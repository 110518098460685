import { LoadingContext } from "../objects/loading_context";
import {
  HomeIcon,
  ReceiptRefundIcon,
  UserCircleIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import {
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "./loading";
import {
  getStorageCompany,
  getStorageCompanyID,
  initials,
  setProfileStorage,
  setStorageCompanies,
  setStorageCompany,
  setStorageCompanyID,
} from "../utils/helper";
import { getProfile } from "../repositories/auth";
import { Avatar } from "rsuite";
import { Company } from "../model/company";
import { Profile } from "../model/auth";
import NavMenu from "./nav";


interface MainLayoutProps {
  onLoadProfile?: (s: Profile) => void;
  onLoadCompany?: (s: Company) => void;
  children: ReactNode;
  hideMenu?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  onLoadProfile,
  onLoadCompany,
  hideMenu,
}) => {
  const { isLoading } = useContext(LoadingContext);

  const [companyID, setCompanyID] = useState<string | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getStorageCompanyID().then((v) => {
      setCompanyID(v);
      if (onLoadCompany) onLoadCompany(v);
      getProfileData();
    });
    getStorageCompany().then((v) => {
      if (onLoadCompany) onLoadCompany(v);
    });
  }, [mounted]);

  const getProfileData = async () => {
    const profileRes = await getProfile();
    let profileResData = await profileRes.json();
    setProfileStorage(profileResData.data);
    setCompanies(profileResData.data.companies);
    if (onLoadProfile!) onLoadProfile(profileResData.data);
  };

  useEffect(() => {
    if (companyID) {
      getProfileData();
    }
  }, [companyID]);

  const renderCompanies = () => (
    <div className="relative bg-gradient-to-b from-white to-blue-300 w-full">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <img src="/images/logo araya.png" className="h-16 mb-8 mx-auto" />
        <div className="text-center  p-12 min-h-10 bg-white shadow-sm z-50 rounded-lg">
          <div className="w-full">
            <h3 className=" text-3xl font-bold mb-4 text-black">Perusahaan</h3>
            <p className=" text-gray-400">Silakan pilih perusahaan</p>
            <ul className="mt-4">
              {companies.map((e) => (
                <li
                  className="w-full text-left flex  hover:font-bold cursor-pointer mb-4 last:mb-0"
                  key={e.id}
                  onClick={() => {
                    setCompanyID(e.id);
                    setStorageCompanyID(e.id);
                    setStorageCompany(e);
                    window.location.reload();
                  }}
                >
                  <div className="">
                    <Avatar
                      size={"sm"}
                      circle
                      src={e.logo_url}
                      alt={initials(e.name)}
                      className="mr-2 w-8 "
                    />
                  </div>
                  <div className="flex flex-col">
                    <p>{e.name}</p>
                    <p>{e.address}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
  return companyID != null ? (
    <div className="flex  h-full w-full">
      <div className={`flex-1 flex flex-col items-center ${!hideMenu && 'pb-12'} h-full`}>
        {children}
      </div>
      {!hideMenu && (
        <NavMenu />
      )}
      {isLoading && <Loading />}
    </div>
  ) : (
    renderCompanies()
  );
};
export default MainLayout;
