import { useContext, useEffect, useState, type FC } from "react";
import WebcamPage from "../components/web_cam";
import { errorToast } from "../utils/helper_ui";
import { LoadingContext } from "../objects/loading_context";
import moment from "moment";
import {
  addAttendance,
  attendanceDetail,
  clockOutAttendance,
  todayAttendance,
} from "../repositories/attendance";
import { useNavigate, useParams } from "react-router-dom";
import { Attendance } from "../model/attendance";
import MainLayout from "../components/main_layout";

interface ClockOutPageProps {}

const ClockOutPage: FC<ClockOutPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [attendance, setAttendance] = useState<Attendance | null>(null);
  const { attendanceId } = useParams();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    todayAttendance()
      .then((v) => v.json())
      .then((v) => {
        if (v.data.id) {
          setAttendance(v.data);
        }
      });
  }, [mounted]);

  useEffect(() => {
    if (attendanceId) {
      attendanceDetail(attendanceId)
        .then((v) => v.json())
        .then((v) => {
          setAttendance(v.data);
        });
    }
  }, [attendanceId]);

  return (
    <MainLayout hideMenu>
      <WebcamPage
        attendance={attendance}
        onCapture={async (val) => {
          if (!val.latitude || !val.longitude) {
            errorToast(
              "WARNING, Lokasi anda tidak terdeteksi, hal ini bisa membuat proses clock out gagal"
            );
          }
          try {
            setIsLoading(true);
            if (!attendanceId) {
              return;
            }
            await clockOutAttendance(attendanceId!, {
              clock_out: moment().toISOString(),
              clock_out_lat: val.latitude,
              clock_out_lng: val.longitude,
              clock_out_picture: val.path,
              clock_in: moment().toISOString(),
              is_face_detected: val.isFaceDetected,
              remarks: val.remarks,
            });
            //   } else {
            // await addAttendance({
            //   clock_in: moment().toISOString(),
            //   clock_in_lat: val.latitude,
            //   clock_in_lng: val.longitude,
            //   clock_in_picture: val.path,
            //   is_face_detected: val.isFaceDetected,
            //   remarks: val.remarks,
            // });
            window.location.href = "/";
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
        onClose={() => {
          window.location.href = "/";
        }}
      />
    </MainLayout>
  );
};
export default ClockOutPage;
