import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { useNavigate, useParams } from "react-router-dom";
import { Profile } from "../model/auth";
import { Overtime } from "../model/overtime";
import {
  ChevronLeftIcon,
  ClockIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import CheckIcon from "@rsuite/icons/legacy/Check";
import { LoadingContext } from "../objects/loading_context";
import {
  clockInOvertime,
  clockOutOvertime,
  getOvertimeDetail,
} from "../repositories/overtime";
import { errorToast, setError, successToast } from "../utils/helper_ui";
import Moment from "react-moment";
import { Avatar, Badge, Button, Modal, Timeline } from "rsuite";
import moment from "moment";
import WebcamPage from "../components/web_cam";
import MapComponent from "../components/map";
import { LiaTasksSolid } from "react-icons/lia";
import ModalTask from "../components/modal_task";
import { nl2br } from "../utils/helper";

interface OvertimeDetailProps {}

const OvertimeDetail: FC<OvertimeDetailProps> = ({}) => {
  const nav = useNavigate();
  const { overtimeId } = useParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [overtime, setOvertime] = useState<Overtime | null>(null);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [showModalClock, setShowModalClock] = useState(false);
  const [clockMode, setClockMode] = useState("CLOCK_IN");
  const [modalTaskOpen, setModalTaskOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted, overtimeId]);
  const getDetail = () => {
    setIsLoading(true);
    getOvertimeDetail(overtimeId!)
      .then((v) => v.json())
      .then((v) => {
        setOvertime(v.data);
      })
      .finally(() => setIsLoading(false))
      .catch((err) => errorToast(err));
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Detail Lembur</h3>
        <div></div>
      </div>
      <div className="flex flex-col gap-4 p-4  rounded-md w-full h-full">
        <div className="flex-1">
          <div className="flex flex-col gap-2">
            <div>
              <p className="font-bold">Tgl Pengajuan</p>
              <Moment format="DD MMM YYYY">{overtime?.date_requested}</Moment>
            </div>

            <div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <p className="font-bold">Mulai</p>
                  <Moment format="HH:mm">{overtime?.start_time_request}</Moment>
                </div>

                <div>
                  <p className="font-bold">Selesai</p>
                  <Moment format="HH:mm">{overtime?.end_time_request}</Moment>
                </div>
              </div>
            </div>
            <div>
              <p className="font-bold">Keterangan</p>
              {overtime?.reason}
            </div>
            <div>
              <p className="font-bold">Status</p>
              {overtime?.status == "PENDING" && (
                <Badge
                  color="cyan"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "REVIEWED" && (
                <Badge
                  color="green"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "APPROVED" && (
                <Badge
                  color="blue"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
              {overtime?.status == "REJECTED" && (
                <Badge
                  color="red"
                  className="text-xs"
                  content={overtime?.status}
                />
              )}
            </div>
            {overtime?.status != "PENDING" && (
              <div>
                {overtime?.approved && (
                  <>
                    <p className="font-bold">Tgl. Approval</p>
                    <Moment format="DD MMM YYYY HH:mm">
                      {overtime?.date_approved_or_rejected}
                    </Moment>
                    <p className="font-bold">Approver</p>
                    <p>{overtime?.approved?.full_name}</p>
                  </>
                )}
                <p className="font-bold">Catatan</p>
                <p>{overtime?.remarks}</p>
              </div>
            )}
            {overtime?.clock_in && (
              <div className="p-4 bg-white rounded-xl shadow-xl shadow-gray-200 flex justify-between items-center flex-col mb-8">
                <Timeline className="custom-timeline">
                  <Timeline.Item dot={<ClockIcon className="rs-icon" />}>
                    <div className="text-lg font-bold justify-between flex items-center">
                      <div className="flex flex-col">
                        <Badge content="Clock In" color="blue" />
                        {/* <Moment format="HH:mm">{attendance.clock_in}</Moment> */}
                        <p>
                          {moment(overtime.clock_in)
                            .format("HH:mm")
                            .replaceAll("pukul", "")}
                        </p>
                      </div>
                      <a
                        href={overtime.clock_in_picture}
                        target="_blank"
                        className="flex items-center justify-center"
                      >
                        <Avatar
                          size="sm"
                          circle
                          src={overtime.clock_in_picture}
                        />
                      </a>
                    </div>

                    <p
                      className="text-sm mb-4 hover:font-bold"
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/place/${overtime.clock_in_lat},${overtime.clock_in_lng}`
                        );
                      }}
                    >
                      {overtime.clock_in_notes}
                    </p>
                    <MapComponent
                      latitude={overtime.clock_in_lat}
                      longitude={overtime.clock_in_lng}
                      locationName={overtime.clock_in_notes}
                      styles={{ borderRadius: 10 }}
                      height={120}
                      width={null}
                    />
                  </Timeline.Item>
                  {/* ACTIVITIES HERE */}
                  {overtime?.attendance?.activities.map((e) => (
                    <Timeline.Item
                      key={e.id}
                      dot={<RocketLaunchIcon className="rs-icon" />}
                      className="cursor-pointer"
                    >
                      <div className="text-lg font-bold justify-between flex items-center ">
                        {/* <Moment format="HH:mm">{`${e.start_date} ${e.start_time}`}</Moment> */}
                        <p>
                          {moment(`${e.start_date} ${e.start_time}`)
                            .format("HH:mm")
                            .replaceAll("pukul", "")}
                        </p>
                      </div>
                      <p
                        className="text-sm  hover:font-bold"
                        onClick={() => {
                          nav(`/task/${e.id}`);
                        }}
                      >
                        {e.name}
                      </p>

                      <p
                        className="text-xs mb-4"
                        dangerouslySetInnerHTML={{
                          __html: nl2br(e.description),
                        }}
                      ></p>
                    </Timeline.Item>
                  ))}
                  {overtime.clock_out && (
                    <Timeline.Item
                      dot={
                        <CheckIcon
                          style={{ background: "#15b215", color: "#fff" }}
                        />
                      }
                    >
                      <div className="text-lg font-bold justify-between flex items-center">
                        <div className="flex flex-col">
                          <Badge content="Clock Out" color="red" />
                          {/* <Moment format="HH:mm">{overtime.clock_out}</Moment> */}
                          <p>
                            {moment(`${overtime.clock_out}`)
                              .format("HH:mm")
                              .replaceAll("pukul", "")}
                          </p>
                        </div>

                        <a
                          href={overtime.clock_out_picture}
                          target="_blank"
                          className="flex items-center justify-center"
                        >
                          <Avatar
                            size="sm"
                            circle
                            src={overtime.clock_out_picture}
                          />
                        </a>
                      </div>
                      <p
                        className="text-sm mb-4 hover:font-bold"
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/place/${overtime.clock_out_lat},${overtime.clock_out_lng}`
                          );
                        }}
                      >
                        {overtime.clock_out_notes}
                      </p>
                      <MapComponent
                        latitude={overtime.clock_out_lat}
                        longitude={overtime.clock_out_lng}
                        locationName={overtime.clock_out_notes}
                        styles={{ borderRadius: 10 }}
                        height={120}
                        width={null}
                      />
                    </Timeline.Item>
                  )}
                </Timeline>
                {!overtime.clock_out && (
                  <div className=" mt-4 w-full">
                    <Button
                      block
                      appearance="primary"
                      color="orange"
                      onClick={() => setModalTaskOpen(true)}
                    >
                      <LiaTasksSolid /> <span>TASK</span>
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mb-4">
          {moment().isAfter(moment(overtime?.start_time_request)) &&
            !overtime?.clock_in &&
            overtime?.status == "APPROVED" && (
              <Button
                block
                appearance="primary"
                color="green"
                onClick={() => {
                  setShowModalClock(true);
                  setClockMode("CLOCK_IN");
                }}
              >
                Clock in
              </Button>
            )}
          {overtime?.clock_in &&
            !overtime?.clock_out &&
            overtime?.status == "APPROVED" && (
              <Button
                block
                appearance="primary"
                color="red"
                onClick={() => {
                  setShowModalClock(true);
                  setClockMode("CLOCK_OUT");
                }}
              >
                Clock Out
              </Button>
            )}
        </div>
      </div>
      <Modal
        classPrefix="cam"
        size={"full"}
        open={showModalClock}
        onClose={() => {
          setShowModalClock(false);
        }}
      >
        <Modal.Body className="">
          {showModalClock && (
            <WebcamPage
              onCapture={async (val) => {
                if (!val.latitude || !val.longitude) {
                  errorToast(
                    "WARNING, Lokasi anda tidak terdeteksi, hal ini bisa membuat proses clock in/out gagal"
                  );
                  console.log(val);
                  return;
                }

                try {
                  setIsLoading(true);
                  if (clockMode == "CLOCK_IN") {
                    await clockInOvertime(overtimeId!, {
                      clock_in: moment().toISOString(),
                      clock_in_lat: val.latitude!,
                      clock_in_lng: val.longitude!,
                      clock_in_picture: val.path,
                      // remarks:val.remarks,
                    });
                  }

                  if (clockMode == "CLOCK_OUT") {
                    await clockOutOvertime(overtimeId!, {
                      clock_out: moment().toISOString(),
                      clock_out_lat: val.latitude!,
                      clock_out_lng: val.longitude!,
                      clock_out_picture: val.path,
                    });
                  }

                  setShowModalClock(false);
                  successToast("Data clock in / clock out telah dikirim");

                  // window.location.reload();
                  getDetail();
                } catch (error) {
                  setError(`${error}`);
                } finally {
                  setIsLoading(false);
                }
              }}
              onClose={() => {
                setShowModalClock(false);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }}
            />
          )}
        </Modal.Body>
      </Modal>
      <ModalTask
        isOpen={modalTaskOpen}
        onClose={setModalTaskOpen}
        profile={profile}
        attendance={overtime?.attendance}
        overtime={overtime}
        onSuccess={() => {
          getDetail();
        }}
      />
    </MainLayout>
  );
};
export default OvertimeDetail;
