import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../components/main_layout";
import type { FC } from "react";
import { BsPlus } from "react-icons/bs";
import { Pagination } from "../objects/pagination";
import { asyncLocalStorage, confirmDelete } from "../utils/helper";
import { TOKEN } from "../utils/constant";
import {
  addReimbursement,
  deleteReimbursement,
  getReimbursements,
} from "../repositories/reimbursement";
import { LoadingContext } from "../objects/loading_context";
import { Reimbursement } from "../model/reimbursement";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import mime from "mime";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Modal,
  Pagination as RSPagination,
} from "rsuite";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { TfiTrash } from "react-icons/tfi";
import { errorToast } from "../utils/helper_ui";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
interface ReimbursementPageProps {}

const ReimbursementPage: FC<ReimbursementPageProps> = ({}) => {
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [attachment, setAttachment] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [token, setToken] = useState("");
  const [description, setDescription] = useState("");
  const [isImg, setIsImg] = useState(false);
  const [reimbursements, setReimbursements] = useState<Reimbursement[]>([]);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());
  const nav = useNavigate();
  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllReimbursement();
  }, [page, limit, search, mounted]);

  const getAllReimbursement = async () => {
    setIsLoading(true);
    getReimbursements({ page, limit, search })
      .then((v) => v.json())
      .then((v) => {
        setReimbursements(v.data);
        setPagination(v.pagination);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <MainLayout hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Reimbursement</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          {reimbursements.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 "
              onClick={() => nav(`/reimbursement/${e.id}`)}
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.date}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.date}
                </Moment>
              </div>
              <div className="flex  flex-1 flex-row justify-between">
                <div className="py-2">
                  {e.status == "DRAFT" && (
                    <Badge
                      color="cyan"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {(e.status == "PROCESSING" || e.status == "REQUEST") && (
                    <Badge
                      color="violet"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "REVIEWED" && (
                    <Badge
                      color="orange"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "APPROVED" && (
                    <Badge
                      color="green"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "REJECTED" && (
                    <Badge color="red" className="text-xs" content={e.status} />
                  )}
                  <p className="text-sm">{e.name}</p>
                  {e.remarks && (
                    <div className=" text-ellipsis overflow-hidden h-[100px]">
                      <p className="text-sm font-semibold mt-4">Catatan</p>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={e.remarks}
                        className={"text-sm"}
                      />
                    </div>
                  )}
                </div>
                <div className="py-4 flex flex-col items-center w-12 justify-between">
                  {e.status == "DRAFT" && (
                    <TfiTrash
                      className="text-red-300"
                      size={18}
                      onClick={() => {
                        confirmDelete(() => {
                          setIsLoading(true);
                          deleteReimbursement(e.id)
                            .then(() => {
                              getAllReimbursement();
                            })
                            .catch((err) => errorToast(`${err}`))
                            .finally(() => setIsLoading(false));
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {reimbursements.length == 0 && (
          <div className="flex justify-center flex-col py-32 h-full px-8">
            <p className="text-center">Belum ada data reimbursement</p>
            <Button
              onClick={() => setModalAddOpen(true)}
              className="w-full"
              color="blue"
              appearance="primary"
            >
              Ajukan Reimbursement
            </Button>
          </div>
        )}

        {pagination?.total_pages !== 1 && (
          <div className="bg-white flex justify-center p-4">
            <RSPagination
              total={pagination?.total_records ?? 0}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        )}
      </div>
      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tambah Reimbursement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Tgl</p>
            <DatePicker
              value={date}
              block
              placement="bottomStart"
              format="dd/MM/yyyy"
              onChange={(val) => {
                setDate(val!);
              }}
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              placeholder="ex: Perdin ke bandung ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              addReimbursement({
                date: date.toISOString(),
                name: description,
              })
                .then((v) => v.json())
                .then((v) => {
                  setModalAddOpen(false);
                  getAllReimbursement();
                  nav(`/reimbursement/${v.data.last_id}`);
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default ReimbursementPage;
