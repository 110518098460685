import { useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import {
  initServiceWorker,
  isStandAlone,
  subscribeToPush,
} from "../utils/helper";
import { Button } from "rsuite";

interface WebPushProps {}

const WebPush: FC<WebPushProps> = ({}) => {
  const [status, setStatus] = useState("");
  const nav = useNavigate();
  const [standAlone, setIsStandAlone] = useState(false);
  useEffect(() => {
    initServiceWorker().then((val) => {
      setStatus(val!);
    });
    setIsStandAlone(isStandAlone());
  }, []);

  return (
    <MainLayout hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>iOS Push Notif</h3>
          <div className="w-8"></div>
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto">
          {/* <h3>Status : {status}</h3> */}
          {!standAlone && (
            <div id="add-to-home-screen">
              Agar WebPush berfungsi, Anda mungkin perlu menambahkan situs web ini ke Layar Utama
              <img
                src="/images/webpush-add-to-home-screen.PNG"
                alt="webpush add to some screen"
              />
            </div>
          )}
          {(status == "prompt" ||
            status == "granted") && (
              <Button
                block
                appearance="ghost"
                id="subscribe_btn"
                onClick={() => {
                  subscribeToPush().then(() => {
                    initServiceWorker().then((val) => {
                      setStatus(val!);
                    });
                  });
                }}
              >
                {status == "granted"
                  ? "Refresh Subscribe Token"
                  : "Subscribe to notifications"}
              </Button>
            )}
        </div>
      </div>
    </MainLayout>
  );
};
export default WebPush;
