import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { Profile } from "../model/auth";
import {
  approvalWorkReport,
  getWorkReportDetail,
} from "../repositories/work_report";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { errorToast, successToast } from "../utils/helper_ui";
import { WorkReport } from "../model/work_report";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Moment from "react-moment";
import { money } from "../utils/helper";
import { Badge, Button, Modal } from "rsuite";
import moment from "moment";
// import QRCode from "react-qr-code";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

interface WorkReportDetailProps {
  needApprovement: boolean;
  needCheck: boolean;
}

const WorkReportDetail: FC<WorkReportDetailProps> = ({ needApprovement, needCheck }) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [mounted, setMounted] = useState(false);
  const { workReportId } = useParams();
  const [workReport, setWorkReport] = useState<WorkReport | null>(null);
  const nav = useNavigate();
  const [remarks, setRemarks] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted]);

  const getDetail = () => {
    getWorkReportDetail(workReportId!)
      .then((v) => v.json())
      .then((v) => {
        setWorkReport(v.data);
      })
      .catch((err) => errorToast(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const approval = async () => {
    try {
      setIsLoading(true);
      await approvalWorkReport(workReportId!, status, remarks);
      getDetail();
      successToast(`Berhasil di ${status}`);
      setOpenModal(false);
      setRemarks("");
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };



  const downloadQR = () => {
    const canvas = document.getElementById("qr-gen") as HTMLCanvasElement;
    canvas.style.stroke = "10px";

    const pngUrl = canvas!
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${workReport?.id}|${moment(
      workReport?.submitted_at
    ).format("YYYY-MM-DD")}|${workReport?.work_type?.name}|${
      workReport?.units_completed
    }|${workReport?.work_type?.unit_name}|${
      workReport?.employee.full_name
    }|NIK-${workReport?.employee?.employee_identity_number}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)}>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Detail Laporan Kerja Harian</h3>
        <div></div>
      </div>
      <div className="flex flex-col  p-4 bg-white rounded-md w-full h-full overflow-y-auto">
        <div className="flex-1 overflow-y-auto flex flex-col gap-4">
          <div className="">
            <p className="text-sm font-bold">Tgl</p>
            <Moment className="text-sm" format="DD MMM YYYY, HH:mm">
              {workReport?.work_date}
            </Moment>
          </div>
          <div className="">
            <p className="text-sm font-bold">Tgl Pengajuan</p>
            <Moment className="text-sm" format="DD MMM YYYY, HH:mm">
              {workReport?.submitted_at}
            </Moment>
          </div>
          {needApprovement ? (
            <div className="">
              <p className="text-sm font-bold">Nama Karyawan</p>
              <p>{workReport?.employee?.full_name}</p>
            </div>
          ) : (
            <div className="">
              <p className="text-sm font-bold">Diajukan Kepada</p>
              <p>{workReport?.approved_by?.full_name}</p>
            </div>
          )}

          <div className="">
            <p className="text-sm font-bold">NIK / NIP</p>
            <p>{workReport?.employee?.employee_identity_number}</p>
          </div>
          <div className="">
            <p className="text-sm font-bold">Status</p>
            <p>
              {workReport?.status == "SUBMITTED" && (
                <Badge content={workReport?.status} color="orange" />
              )}
              {workReport?.status == "APPROVED" && (
                <Badge content={workReport?.status} color="green" />
              )}
              {workReport?.status == "REJECTED" && (
                <Badge content={workReport?.status} color="red" />
              )}
            </p>
          </div>

          {workReport?.status != "SUBMITTED" && (
            <div className="">
              <p className="text-sm font-bold">
                Tgl {workReport?.status == "APPROVED" ? "Disetujui" : "Ditolak"}
              </p>
              <Moment className="text-sm" format="DD MMM YYYY, HH:mm">
                {workReport?.approved_date}
              </Moment>
            </div>
          )}
          {workReport?.status != "SUBMITTED" && (
            <div className="">
              <p className="text-sm font-bold">
                {workReport?.status == "APPROVED" ? "Disetujui" : "Ditolak"}
              </p>
              {workReport?.approved_by?.full_name}
            </div>
          )}
          <div className="">
            <p className="text-sm font-bold">Jenis Pekerjaan</p>
            <p>{workReport?.work_type?.name}</p>
          </div>
          <div className="">
            <p className="text-sm font-bold">Unit Pekerjaan</p>
            <p>{workReport?.work_type?.unit_name}</p>
          </div>
          <div className="">
            <p className="text-sm font-bold">Jumlah</p>
            <p>
              {money(workReport?.units_completed)}{" "}
              {workReport?.work_type?.unit_name}
            </p>
          </div>
          <div className="">
            <p className="text-sm font-bold mb-2">Files</p>
            <div className="flex flex-wrap gap-2">
              {(workReport?.pictures ?? []).map((e) => (
                <img key={e.id}
                  src={e.picture_url}
                  className="w-16 rounded-lg aspect-square object-cover cursor-pointer"
                  onClick={() => window.open(e.picture_url)}
                />
              ))}
            </div>
          </div>
          {workReport?.status == "APPROVED" && (
            <div className="flex flex-col justify-center w-32">
              <p className="text-sm font-bold">QR</p>
              <QRCodeCanvas
                marginSize={2}
                size={128}
                id="qr-gen"
                className="p-4"
                value={`WORK_REPORT|${workReport?.id}|${moment(
                  workReport?.submitted_at
                ).format("YYYY-MM-DD")}|${workReport?.work_type?.name}|${
                  workReport?.units_completed
                }|${workReport?.work_type?.unit_name}|${
                  workReport?.employee.full_name
                }|NIK-${workReport?.employee?.employee_identity_number}|${workReport?.pictures?.map(e => e.picture_url).join(",")}`}
              />
              <Button onClick={downloadQR}>Download</Button>
            </div>
          )}
        </div>
        {needApprovement && workReport?.status == "SUBMITTED" && (
          <div className="flex flex-row gap-2 justify-center items-center">
            <Button
              className="p-0 m-0"
              block
              color="green"
              appearance="primary"
              onClick={async () => {
                setStatus("APPROVED");
                setOpenModal(true);
              }}
            >
              APPROVE
            </Button>
            <Button
              className="p-0 m-0"
              style={{ marginTop: 0 }}
              block
              color="red"
              appearance="primary"
              onClick={async () => {
                setStatus("REJECTED");
                setOpenModal(true);
              }}
            >
              REJECT
            </Button>
          </div>
        )}
      </div>
      <Modal size={"xs"} open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>
          <Modal.Title>
            {status == "APPROVED" ? "SETUJUI" : "TOLAK"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-sm">Catatan</p>
          <textarea
            className="w-full border rounded-md p-2"
            rows={9}
            value={remarks}
            placeholder="Catatan"
            onChange={(e) => setRemarks(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="p-0 m-0"
            block
            appearance="primary"
            onClick={approval}
          >
            KIRIM
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default WorkReportDetail;
