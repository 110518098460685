import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import { Button, DatePicker, Modal, Uploader } from "rsuite";
import { asyncLocalStorage } from "../utils/helper";
import { TOKEN } from "../utils/constant";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import { createActivity, updateActivity } from "../repositories/activity";
import { profile } from "console";
import { Profile } from "../model/auth";
import { FaClock } from "react-icons/fa6";
import { Attendance } from "../model/attendance";
import { Activity } from "../model/activity";

interface ModalVisitProps {
  profile?: Profile | null;
  attendance?: Attendance | null;
  activity?: Activity | null;
  isOpen: boolean;
  lat?: number | null;
  lng?: number | null;
  mode: string;
  picturePath?: string;
  onClose?: (s: boolean) => void;
  onSuccess?: () => void;
}

const ModalVisit: FC<ModalVisitProps> = ({
  isOpen,
  onClose,
  profile,
  onSuccess,
  attendance,
  lat,
  lng,
  mode,
  picturePath,
  activity,
}) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState<Date>(moment().toDate());
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [paths, setPaths] = useState<string[]>([]);
  const [assignedToId, setAssignedToId] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  // const [location, setLocation] = useState<GeolocationPosition | null>(null);

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
  }, []);
  // useEffect(() => {
  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setLocation(position);
  //     },
  //     (error) => {
  //       errorToast("Lokasi tidak dapat diakses");
  //     }
  //   );
  // } else {
  //   errorToast(
  //     "Geolocation tidak didukung oleh browser Anda, data geolocation diperlukan untuk mengetahui posisi anda ketika melakukan absen"
  //   );
  // }
  // setLocation(position);
  // }, [lat, lng]);

  useEffect(() => {
    setDescription(activity?.description ?? "");
    setName(activity?.name ?? "");
  }, [activity]);

  useEffect(() => {
    setAssignedToId(profile?.employee_id ?? null);
  }, [profile]);
  return (
    <Modal
      size={"xs"}
      open={isOpen}
      onClose={() => {
        onClose?.(false);
      }}

    >
      <Modal.Header>
        <Modal.Title>
          {activity ? activity?.name : "Tambah Kunjungan"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
      >
        {mode == "VISIT_IN" && (
          <div className="mb-4">
            <p className="text-sm font-[700]">Nama Kunjungan</p>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nama Kunjungan / Lokasi"
            />
          </div>
        )}
        {/* <div className="mb-4">
          <p className="text-sm font-[700]">Tgl</p>
          <DatePicker
            block
            value={date}
            onChange={(val) => setDate(val!)}
            format="dd MMM yyyy"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm font-[700]">Jam</p>
          <DatePicker
            value={startTime}
            block
            format="HH:mm"
            caretAs={FaClock}
            placement="bottomStart"
            onChange={(val) => {
              setStartTime(val);
            }}
          />
          <p className="text-sm font-[700]">s/d</p>
          <DatePicker
            value={endTime}
            block
            format="HH:mm"
            caretAs={FaClock}
            placement="bottomStart"
            onChange={(val) => {
              setEndTime(val);
            }}
          />
        </div> */}

        <div className="mb-4">
          <p className="text-sm font-[700]">Keterangan</p>
          <textarea
            className="form-control"
            value={description}
            rows={5}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Keterangan"
          />
        </div>

        {mode != "VISIT_IN" && (
          <div className="mb-4">
            <p className="text-sm font-[700]">File</p>
            <Uploader
              draggable
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf"
              onSuccess={(response, file) => {
                setIsLoading(false);
                console.log(response.data.path);
                paths.push(response.data.path);
                setPaths([...paths]);
                // setAttachment(response.data.path);

                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                errorToast("Upload Failed");
              }}
            >
              <div
                style={{
                  height: 200,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Click or Drag files to this area to upload</span>
              </div>
            </Uploader>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onClose?.(false);
          }}
        >
          Batal
        </Button>
        <Button
          appearance="primary"
          onClick={async () => {
            try {
              if (!name) {
                throw Error("Isi Nama Task");
              }
              if (!lat || !lng) {
                throw Error("Aktifkan Lokasi");
              }
              // if (!startTime) {
              //   throw Error("Isi Jam terlebih dahulu");
              // }
              setIsLoading(true);
              if (mode == "VISIT_IN") {
                await createActivity({
                  lat: lat,
                  lng: lng,
                  activity_type: "VISIT",
                  start_date: date.toISOString(),
                  start_time: moment().format("HH:mm:ss"),
                  end_time: null,
                  description,
                  assigned_to_id: assignedToId,
                  name,
                  attendance_id: attendance?.id,
                  start_time_picture: picturePath,
                  end_time_picture: null,
                  status: "IN_PROGRESS",
                  files: paths.map((e) => ({
                    path: e,
                    url: "",
                    filename: e.replace(/^.*[\\/]/, ""),
                    caption: e.replace(/^.*[\\/]/, ""),
                  })),
                });
              } else {
                await updateActivity(activity!.id, {
                  activity_type: "VISIT",
                  visit_out_lat: lat,
                  visit_out_lng: lng,
                  start_date: moment(activity?.start_date).toISOString(),
                  end_time: moment().format("HH:mm:ss"),
                  description: description ?? "",
                  end_time_picture: picturePath,
                  status: "FINISHED",
                  files: paths.map((e) => ({
                    path: e,
                    url: "",
                    filename: e.replace(/^.*[\\/]/, ""),
                    caption: e.replace(/^.*[\\/]/, ""),
                  })),
                });
              }

              onClose?.(false);
              onSuccess?.();
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalVisit;
