import { ChevronLeftIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import mime from "mime";
import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import CurrencyInput from "react-currency-input-field";
import { IoDocumentAttachOutline } from "react-icons/io5";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Divider,
  Modal,
  Uploader,
} from "rsuite";
import MainLayout from "../components/main_layout";
import { Profile } from "../model/auth";
import { CashAdvance } from "../model/cash_advance";
import { LoadingContext } from "../objects/loading_context";
import {
  addUsageCashAdvance,
  getCashAdvanceDetail,
  refundCashAdvance,
} from "../repositories/cash_advance";
import { asyncLocalStorage, money } from "../utils/helper";
import { errorToast, successToast } from "../utils/helper_ui";
import { TOKEN } from "../utils/constant";
import { Transaction } from "../model/transaction";

interface CashAdvanceDetailProps {}

const CashAdvanceDetail: FC<CashAdvanceDetailProps> = ({}) => {
  const nav = useNavigate();
  const { cashAdvanceId } = useParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [cashAdvance, setCashAdvance] = useState<CashAdvance | null>(null);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [token, setToken] = useState("");
  const [mountValue, setMountValue] = useState(true);
  const [description, setDescription] = useState("");
  const [isImg, setIsImg] = useState(false);
  const [date, setDate] = useState(moment().toDate());
  const [modalRefundOpen, setModalRefundOpen] = useState(false);
  const [amountRefund, setAmountRefund] = useState(0);
  const [refunds, setRefunds] = useState<Transaction[]>([]);

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    setMounted(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getDetail();
  }, [mounted, cashAdvanceId]);

  const getDetail = () => {
    if (!mounted) return;
    if (!cashAdvanceId) return;
    getCashAdvanceDetail(cashAdvanceId!)
      .then((v) => v.json())
      .then((v) => {
        setCashAdvance(v.data);
      })
      .catch((err) => errorToast(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (cashAdvance) {
      setRefunds(cashAdvance.transactions.filter((e) => e.is_refund));
    }
  }, [cashAdvance]);

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Detail Cash Advance</h3>
        <div></div>
      </div>
      <div className="flex flex-col gap-4 p-4 bg-white rounded-md w-full h-full">
        <div className="flex flex-col gap-2">
          <div>
            <p className="font-semibold">Tgl Pengajuan</p>
            <Moment format="DD MMM YYYY">{cashAdvance?.date_requested}</Moment>
          </div>

          <div>
            <p className="font-semibold">Total Pengajuan</p>
            {money(cashAdvance?.amount_requested)}
          </div>
          {cashAdvance?.date_approved_or_rejected && (
            <div>
              <p className="font-semibold">Tgl Disetujui / Ditolak</p>
              <Moment format="DD MMM YYYY">
                {cashAdvance?.date_approved_or_rejected}
              </Moment>
            </div>
          )}
          {cashAdvance?.status == "APPROVED" && (
            <>
              <div>
                <p className="font-semibold">Jumlah Yang Disetujui</p>
                {money(cashAdvance?.amount)}
              </div>
              <div>
                <p className="font-semibold">Sisa Dana</p>
                {money(cashAdvance?.remaining_amount)}
              </div>
            </>
          )}
          <div>
            <p className="font-semibold">Approver</p>
            {cashAdvance?.approved?.full_name}
          </div>
          <div>
            <p className="font-semibold">Status</p>
            {cashAdvance?.status == "PENDING" && (
              <Badge
                className="text-center"
                color="yellow"
                content={cashAdvance?.status}
              />
            )}
            {cashAdvance?.status == "APPROVED" && (
              <Badge
                className="text-center"
                color="blue"
                content={cashAdvance?.status}
              />
            )}
            {cashAdvance?.status == "REJECTED" && (
              <Badge
                className="text-center"
                color="red"
                content={cashAdvance?.status}
              />
            )}
          </div>
          <div>
            <p className="font-semibold">Status Refund</p>
            {cashAdvance?.refund_status == "PENDING" && (
              <Badge
                className="text-center"
                color="yellow"
                content={cashAdvance?.refund_status}
              />
            )}
            {cashAdvance?.refund_status == "COMPLETED" && (
              <Badge
                className="text-center"
                color="green"
                content={cashAdvance?.refund_status}
              />
            )}
         
            </div>
          <div>
            {cashAdvance?.file_url && (
              <div>
                <Link to={cashAdvance?.file_url ?? ""}>
                  <PaperClipIcon className="w-6" />
                </Link>
              </div>
            )}
          </div>
          {(cashAdvance?.status == "APPROVED" && cashAdvance?.refund_status != "COMPLETED") && (
            <div className="mt-4">
              <Button
                appearance="ghost"
                block
                color="violet"
                onClick={() => setModalOpen(true)}
              >
                + Penggunaan Dana
              </Button>
            </div>
          )}
          {cashAdvance?.status == "APPROVED" && (
            <div className="mt-4">
              {cashAdvance.usages.map((e) => (
                <div
                  key={e.id}
                  className="mb-4 flex justify-between items-center"
                >
                  <div>
                    <p className="text-sm font-bold">{e.description}</p>
                    <p className="text-sm">
                      {moment(e.date).format("DD MMM YYYY")}
                    </p>
                    <Link to={e.url} target="_blank">
                      {mime.getType(e.url)?.includes("image") ? (
                        <Avatar circle src={e.url} />
                      ) : (
                        <IoDocumentAttachOutline
                          size={16}
                          className="text-gray-400"
                        />
                      )}
                    </Link>
                  </div>
                  <small>{money(e.amount)}</small>
                </div>
              ))}
              <Divider />
              <div className="mb-4 flex justify-between items-center">
                <div>
                  <p className="text-sm font-bold">Total</p>
                </div>
                <small>
                  {money(
                    cashAdvance.usages
                      .map((e) => e.amount)
                      .reduce((a, b) => a + b, 0)
                  )}
                </small>
              </div>

              {refunds.length > 0 && (
                <>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-sm font-bold">Refund Dana</p>
                    </div>
                  </div>
                  {refunds.map((e) => (
                    <div
                      key={e.id}
                      className="flex justify-between items-center border-b last:border-b-0 py-2 my-2"
                    >
                      <div className="flex gap-2">
                        <Moment className="text-xs" format="DD MMM YYYY">
                          {e.date}
                        </Moment>
                        <p className="text-sm">{e.description}</p>
                      </div>
                      <small className="w-32 text-right">
                        {money(e.credit)}
                      </small>
                    </div>
                  ))}
                </>
              )}
              {money(cashAdvance.remaining_amount) > 0 && (
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm font-bold">Sisa</p>
                  </div>
                  <small>{money(cashAdvance.remaining_amount)}</small>
                </div>
              )}
              {cashAdvance?.status == "APPROVED" &&
                cashAdvance.refund_status != "COMPLETED" && (
                  <div className="mt-4">
                    <Button
                      appearance="ghost"
                      block
                      color="orange"
                      onClick={() => {
                        setModalRefundOpen(true);
                        setAmountRefund(cashAdvance?.remaining_amount ?? 0);
                      }}
                    >
                      Refund Dana
                    </Button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      <Modal
        open={modalRefundOpen}
        onClose={() => setModalRefundOpen(false)}
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title>Refund Dana</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Jumlah </p>
            {mountValue ? (
              <CurrencyInput
                className="form-control"
                groupSeparator="."
                decimalSeparator=","
                value={amountRefund}
                onValueChange={(value, _, values) => {
                  setAmountRefund(values?.float ?? 0);
                }}
              />
            ) : (
              <div className="form-control"> {money(amountRefund)}</div>
            )}
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              placeholder="ex: bensin ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">File</p>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf"
              onSuccess={(response, file) => {
                setIsLoading(false);
                setAttachment(response.data.path);
                setAttachmentUrl(response.data.url);
                setIsImg(mime.getType(response.data.url)!.includes("image"));
                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                errorToast("Error Upload");
              }}
            >
              <button
                style={{
                  width: attachmentUrl ? 300 : 100,
                  height: attachmentUrl ? 300 : 100,
                }}
              >
                {attachment ? (
                  isImg ? (
                    <img src={attachmentUrl} alt="" />
                  ) : (
                    <IoDocumentAttachOutline size={64} />
                  )
                ) : (
                  <PaperClipIcon className="w-8" />
                )}
              </button>
            </Uploader>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              refundCashAdvance(
                cashAdvanceId!,
                amountRefund,
                description,
                attachment ?? null
              )
                .then(() => {
                  getDetail();
                  setModalRefundOpen(false);
                  successToast("Berhasil");
                  setAmountRefund(0);
                  setDescription("");
                  setDate(moment().toDate());
                  setAttachment("");
                  setAttachmentUrl("");
                  setMountValue(false);
                  setTimeout(() => {
                    setMountValue(true);
                  }, 300);
                })
                .catch((err) => {
                  errorToast(`${err}`);
                })
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} size={"xs"}>
        <Modal.Header>
          <Modal.Title>Tambah Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Tgl</p>
            <DatePicker
              block
              format="dd MMM yyyy"
              value={date}
              onChange={(val) => setDate(val!)}
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">Jumlah </p>
            {mountValue ? (
              <CurrencyInput
                className="form-control"
                groupSeparator="."
                decimalSeparator=","
                value={amount}
                onValueChange={(value, _, values) => {
                  setAmount(values?.float ?? 0);
                }}
              />
            ) : (
              <div className="form-control"> {money(amount)}</div>
            )}
          </div>
          <div className="mb-4">
            <p className="font-[700]">File</p>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf"
              onSuccess={(response, file) => {
                setIsLoading(false);
                setAttachment(response.data.path);
                setAttachmentUrl(response.data.url);

                setIsImg(mime.getType(response.data.url)!.includes("image"));
                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                errorToast("Error Upload");
              }}
            >
              <button
                style={{
                  width: attachmentUrl ? 300 : 100,
                  height: attachmentUrl ? 300 : 100,
                }}
              >
                {attachment ? (
                  isImg ? (
                    <img src={attachmentUrl} alt="" />
                  ) : (
                    <IoDocumentAttachOutline size={64} />
                  )
                ) : (
                  <PaperClipIcon className="w-8" />
                )}
              </button>
            </Uploader>
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              placeholder="ex: bensin ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              addUsageCashAdvance(cashAdvanceId!, {
                date: date.toISOString(),
                description: description,
                amount: amount,
                file: attachment,
              })
                .then(() => {
                  getDetail();
                  setModalOpen(false);
                  successToast("Berhasil");
                  setAmount(0);
                  setDescription("");
                  setDate(moment().toDate());
                  setAttachment("");
                  setAttachmentUrl("");
                  setMountValue(false);
                  setTimeout(() => {
                    setMountValue(true);
                  }, 300);
                })
                .catch((err) => {
                  errorToast(`${err}`);
                })
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default CashAdvanceDetail;
