import React from "react";
import { Profile } from "../model/auth";
import { LoadingContext } from "../objects/loading_context";
import { getProfile, login, resetPassword } from "../repositories/auth";
import { asyncSetStorage, initials } from "../utils/helper";
import { useContext, useState, type FC } from "react";
import { FaCircleNotch } from "react-icons/fa6";
import Swal from "sweetalert2";
import { Company } from "../model/company";
import { Avatar, Button, Modal } from "rsuite";
import { EyeIcon } from "@heroicons/react/24/outline";
import { errorToast, successToast } from "../utils/helper_ui";

interface LoginProps {}

const Login: FC<LoginProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fcmToken, setFcmToken] = useState("");
  const [device, setDevice] = useState("web");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [modalReset, setModalReset] = useState(false);

  const loginProcess = async () => {
    try {
      setIsLoading(true);
      const loginRes = await login({ email, password, fcmToken, device });
      const loginResJson = await loginRes.json();
      setCompanies(loginResJson.companies);
      await asyncSetStorage({
        token: loginResJson.token,
        permissions: [],
        profile: null,
      });

      const profileRes = await getProfile();
      const profileResJson = await profileRes.json();
      const permissions = profileResJson.data.permissions as string[];
      const profile = profileResJson.data as Profile[];
      await asyncSetStorage({
        token: loginResJson.token,
        permissions,
        profile,
      });
      window.location.href = "/";
    } catch (error) {
      Swal.fire(`Attention`, `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="relative bg-gradient-to-b from-white to-blue-300 w-full">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <img src="/images/logo araya.png" className="h-16 mb-8 mx-auto" />
        <div className="text-center  p-12 min-h-10 bg-white shadow-sm z-50 rounded-lg">
          <h3 className=" text-3xl font-bold mb-4 text-black">Masuk</h3>
          <p className=" text-gray-400">
            Silakan masuk menggunakan akun perusahaan{" "}
          </p>
          <div className="text-left  mt-4">
            <label htmlFor="email" className="px-2">
              Email <span className="required">*</span>
            </label>
            <input
              value={email}
              onChange={(el) => setEmail(el.target.value)}
              type="text"
              className="form-control"
              placeholder="email@company.com"
            />
          </div>
          <div className="text-left  mt-4">
            <label htmlFor="password" className="px-2">
              Password <span className="required">*</span>
            </label>
            <div className="relative">
              <input
                value={password}
                onChange={(el) => setPassword(el.target.value)}
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="****************"
                onKeyUp={(e) => {
                  if (e.key == "Enter") {
                    loginProcess();
                  }
                }}
              />
              <EyeIcon
                className="absolute top-2 right-2 w-4"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
          </div>

          <button
            disabled={isLoading}
            onClick={loginProcess}
            className={`text-white mt-16 inline-flex items-center justify-center bg-blue-300 hover:bg-blue-700 py-2 px-8 w-full font-semibold rounded-xl ${
              isLoading && "disabled:cursor-not-allowed"
            }`}
          >
            {isLoading && <FaCircleNotch className="animate-spin mr-2" />} Login
          </button>
          <div
            className="text-center mt-4 text-sm text-sky-500"
            onClick={() => setModalReset(true)}
          >
            Reset Password
          </div>
        </div>
      </div>
      <Modal open={modalReset} onClose={() => setModalReset(false)}>
        <Modal.Header>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 ">
            <p className="text-base mx-2 font-[700]">Email</p>
            <input
              className="form-control"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="ghost"
            color="blue"
            onClick={() => {
              setModalReset(false);
              setEmail("");
            }}
          >
            Close
          </Button>
          <Button
            color="blue"
            appearance="primary"
            onClick={() => {
              setIsLoading(true);
              resetPassword({ email, password, fcmToken, device })
                .then((res) => {
                  successToast("Silakan cek email anda");
                  setModalReset(false);
                  setEmail("");
                })
                .catch((err) => {
                  errorToast(err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Login;
