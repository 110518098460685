import MainLayout from "../components/main_layout";
import { Profile } from "../model/auth";
import { LoadingContext } from "../objects/loading_context";
import { EMPLOYEE_STATUS, TOKEN } from "../utils/constant";
import { asyncLocalStorage, clearStorage, initials, IsIOS } from "../utils/helper";
import { useContext, useEffect, useState, type FC } from "react";
import { Avatar, Button, Modal, Uploader } from "rsuite";
import { errorToast } from "../utils/helper_ui";
import { changePassword, getProfile, updateAvatar } from "../repositories/auth";
import { Company } from "../model/company";
import Moment from "react-moment";
import {
  ChevronRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

interface ProfilePageProps {}

const ProfilePage: FC<ProfilePageProps> = ({}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [token, setToken] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalDataOpen, setModalDataOpen] = useState(false);
  const [modalPasswordOpen, setModalPasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const profileRes = await getProfile();
    let profileResData = await profileRes.json();
    setProfile(profileResData.data);
  };

  return (
    <MainLayout
      onLoadProfile={(val) => {
        setProfile(val);
      }}
      onLoadCompany={(val) => setCompany(val)}
    >
      <div className="flex h-screen flex-col  w-full px-4 pt-12 pb-4 overflow-y-auto flex-1">
        <Uploader
          fileListVisible={false}
          action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
          onUpload={(file) => {
            setIsLoading(true);
          }}
          headers={{
            authorization: `Bearer ${token}`,
          }}
          accept="image/*"
          onSuccess={(response, file) => {
            setIsLoading(false);
            console.log(response.data);
            updateAvatar(response.data.path).then((v) => {
              getProfileData();
            });
            // toaster.push(<Message type="success">Uploaded successfully</Message>);
          }}
          onError={() => {
            setIsLoading(false);
            errorToast("error upload");
          }}
        >
          <Avatar
            color="blue"
            src={profile?.picture}
            alt={initials(profile?.full_name)}
            circle
            size="lg"
          />
        </Uploader>
        <p className="text-3xl font-bold">{profile?.full_name}</p>
        <p className="text-sm text-gray-600">{profile?.email}</p>
        <p>{profile?.role_name}</p>

        <div className="py-8">
          <p className="text-xl font-bold px-4">Pengaturan</p>
          <div className="bg-white rounded-lg shadow-sm p-4 mt-2 mb-4">
            <div className="">
              <ul>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0"
                  onClick={() => setModalDataOpen(true)}
                >
                  <p className="font-[600]">Data Karyawan</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/payslip")}
                >
                  <p className="font-[600]">Payslip</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => setModalPasswordOpen(true)}
                >
                  <p className="font-[600]">Ganti Password</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => window.open('https://wa.me/628977726030')}
                >
                  <p className="font-[600]">Hubungi CS</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                {IsIOS() &&     <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav('/webpush')}
                >
                  <p className="font-[600]">iOS Push Notif</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>}
              </ul>
            </div>
          </div>
          <p className="text-xl font-bold px-4">Fitur Lainnya</p>
          <div className="bg-white rounded-lg shadow-sm p-4 mt-2 mb-4">
            <div className="">
              <ul>
               
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/announcement")}
                >
                  <p className="font-[600]">Pengumuman</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/cash_advance")}
                >
                  <p className="font-[600]">Cash Advance</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/employee_loan")}
                >
                  <p className="font-[600]">Pinjaman</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/overtime")}
                >
                  <p className="font-[600]">Lembur</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/work_report")}
                >
                  <p className="font-[600]">Laporan Kerja Harian</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
                <li
                  className="flex justify-between items-center mb-4 last:mb-0 cursor-pointer"
                  onClick={() => nav("/resignation")}
                >
                  <p className="font-[600]">Resignation</p>
                  <ChevronRightIcon className="w-6 text-gray-500" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 w-full">
        <Button
          color="red"
          appearance="primary"
          className="w-full"
          onClick={() => {
            clearStorage();
            window.location.href = "/login";
          }}
        >
          Logout
        </Button>
      </div>
      <Modal open={modalDataOpen} onClose={() => setModalDataOpen(false)}>
        <Modal.Header>
          <Modal.Title>Data Karyawan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="text-lg  font-[700]"> {company?.name}</p>
            <p className="text-sm  leading-5">
              {company?.address} {company?.phone} {company?.email}{" "}
            </p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Tgl Lahir</p>
            <Moment format="DD MMM YYYY" className="text-sm">
              {profile?.employee?.date_of_birth}
            </Moment>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Tgl Bergabung</p>
            <Moment format="DD MMM YYYY" className="text-sm">
              {profile?.employee?.started_work}
            </Moment>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Jenis Kelamin</p>
            <p className="text-sm">
              {profile?.employee?.gender == "m" ? "Laki-Laki" : "Perempuan"}
            </p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Status Pekerjaan</p>
            <p className="text-sm">
              {
                EMPLOYEE_STATUS.find(
                  (v) => v.value == profile?.employee?.working_type
                )?.label
              }
            </p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Jabatan</p>
            <p className="text-sm">{profile?.employee?.job_title}</p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Cabang</p>
            <p className="text-sm">{profile?.employee?.branch}</p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Telp</p>
            <p className="text-sm">{profile?.employee?.phone}</p>
          </div>
          <div className="mb-2">
            <p className="font-[700]">Alamat</p>
            <p className="text-sm">{profile?.employee?.address}</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        open={modalPasswordOpen}
        onClose={() => setModalPasswordOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 ">
            <p className="text-base mx-2 font-[700]">Password Baru</p>
            <div className="relative">
              <input
                className="form-control"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password Baru"
              />
              <div
                className="absolute right-2 top-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 text-gray-600" />
                ) : (
                  <EyeSlashIcon className="w-4 text-gray-600" />
                )}
              </div>
            </div>
          </div>
          <div className="mb-4 ">
            <p className="text-base mx-2 font-[700]">Ulangi Password</p>
            <div className="relative">
              <input
                className="form-control"
                type={showPassword ? "text" : "password"}
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                placeholder="Ulangi Password"
              />
              <div
                className="absolute right-2 top-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="w-4 text-gray-600" />
                ) : (
                  <EyeSlashIcon className="w-4 text-gray-600" />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={async () => {
              try {
                if (password != confirm) {
                  throw Error("Password tidak cocok");
                }
                setIsLoading(true);
                await changePassword(password);
                getProfile();
                setModalPasswordOpen(false);
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default ProfilePage;
