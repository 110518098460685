import { type FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import React from 'react';
import Verification from '../pages/Verification';


interface AppRouteProps { }

export const AppRoute: FC<AppRouteProps> = ({ }) => {

    const token = localStorage.getItem("token");
    return (<BrowserRouter>
        <Routes>
        <Route path='/verification/:token' element={<Verification />} />
                {token
                    ? <Route path="/*" element={<PrivateRoutes />} />
                    : <Route path="/*" element={<PublicRoutes />} />}
        </Routes>
    </BrowserRouter>);
};
