import { useContext, useEffect, useState, type FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../components/main_layout";
import { BsPlus } from "react-icons/bs";
import {
  addItemReimbursement,
  deleteItemReimbursement,
  reimbursementDetail,
  reimbursementProcess,
} from "../repositories/reimbursement";
import { Reimbursement } from "../model/reimbursement";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import Moment from "react-moment";
import { asyncLocalStorage, confirmDelete, money } from "../utils/helper";
import {
  Avatar,
  Badge,
  Button,
  Message,
  Modal,
  toaster,
  Uploader,
} from "rsuite";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import mime from "mime";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { TfiTrash } from "react-icons/tfi";
import { TOKEN } from "../utils/constant";
import CurrencyInput from "react-currency-input-field";
import { ChevronLeftIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import moment from "moment";
interface ReimbursementDetailProps {}

const ReimbursementDetail: FC<ReimbursementDetailProps> = ({}) => {
  const { reimbursementId } = useParams();
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [reimbursement, setReimbursement] = useState<Reimbursement | null>(
    null
  );
  
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [token, setToken] = useState("");
  const [mountValue, setMountValue] = useState(true);
  const [description, setDescription] = useState("");
  const [isImg, setIsImg] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted]);

  const getDetail = () => {
    setIsLoading(false);
    reimbursementDetail(reimbursementId!)
      .then((res) => res.json())
      .then((res) => {
        setReimbursement(res.data);
      })
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <MainLayout
    hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>{reimbursement?.name}</h3>
          {reimbursement?.status == "DRAFT" ? (
            <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          <div className="mb-4">
            <p className="text-sm font-bold">Tgl</p>
            <Moment className="text-sm" format="DD MMM YYYY">
              {reimbursement?.date}
            </Moment>
          </div>
          {/* <div className="mb-4">
            <p className="text-sm font-bold">Judul</p>
            <p className="text-sm">{reimbursement?.name}</p>
          </div> */}
          <div className="mb-4">
            <p className="text-sm font-bold">Total</p>
            <p className="text-sm">{money(reimbursement?.total)}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm font-bold">Sisa</p>
            <p className="text-sm">{money(reimbursement?.balance)}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm font-bold">Catatan</p>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={reimbursement?.remarks}
              className={"text-sm"}
            />
          </div>

          <div className="mb-4">
            <p className="text-sm font-bold">Status</p>
            {reimbursement?.status == "DRAFT" && (
              <Badge
                color="cyan"
                className="text-xs"
                content={reimbursement?.status}
              />
            )}
            {(reimbursement?.status == "REQUEST" ||
              reimbursement?.status == "PROCESSING") && (
              <Badge
                color="violet"
                className="text-xs"
                content={reimbursement?.status}
              />
            )}
            {reimbursement?.status == "REVIEWED" && (
              <Badge
                color="orange"
                className="text-xs"
                content={reimbursement?.status}
              />
            )}
            {reimbursement?.status == "APPROVED" && (
              <Badge
                color="green"
                className="text-xs"
                content={reimbursement?.status}
              />
            )}
            {reimbursement?.status == "REJECTED" && (
              <Badge
                color="red"
                className="text-xs"
                content={reimbursement?.status}
              />
            )}
          </div>
          {(reimbursement?.attachments ?? []).length > 0 && (
            <div className="mb-4">
              <p className="text-sm font-bold">Files</p>
              <div className="flex">
                {(reimbursement?.attachments ?? []).map((e) => (
                  <div key={e}>
                    <Link to={e} target="_blank">
                      {mime.getType(e)?.includes("image") ? (
                        <Avatar circle src={e} />
                      ) : (
                        <IoDocumentAttachOutline
                          size={32}
                          className="text-gray-400"
                        />
                      )}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="mb-2">
            <p className="text-sm font-bold mx-2">Item</p>
          </div>
          {(reimbursement?.items ?? []).map((e) => (
            <div
              key={e.id}
              className="flex bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 flex-row justify-between"
            >
              <div>
                <p className="text-sm">
                  #{(reimbursement?.items ?? []).indexOf(e) + 1} {e.notes}
                </p>
                <p className="text-sm">{money(e.amount)}</p>
              </div>
              <div className="flex justify-center flex-col items-center gap-4 w-12">
                {e.attachments.map((att) => {
                  if (mime.getType(att)?.includes("image")) {
                    return <Avatar circle src={att} onClick={() => window.open(att)} />;
                  }
                  return (
                    <>
                    <IoDocumentAttachOutline
                      size={32}
                      className="text-gray-400"
                      onClick={() => window.open(att)}
                    />
                    </>
                  );
                })}
                {reimbursement?.status == "DRAFT" && (
                  <TfiTrash
                    className="text-red-300"
                    size={18}
                    onClick={() => {
                      confirmDelete(() => {
                        setIsLoading(true);
                        deleteItemReimbursement(reimbursement?.id, e.id)
                          .then(() => {
                            getDetail();
                          })
                          .catch((err) => errorToast(`${err}`))
                          .finally(() => setIsLoading(false));
                      });
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {(reimbursement?.transactions ?? []).filter(
            (e) => e.is_reimbursement_payment
          ).length > 0 && (
            <>
              <div className="mb-2">
                <p className="text-sm font-bold mx-2">Pembayaran</p>
              </div>
              {(reimbursement?.transactions ?? [])
                .filter((e) => e.is_reimbursement_payment)
                .map((e) => (
                  <div className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0">
                    <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                      <Moment format="DD">{e.date}</Moment>
                      <Moment
                        format="MMM YYYY"
                        className="text-[8pt] text-center text-red-400"
                      >
                        {e.date}
                      </Moment>
                    </div>
                    <div className="flex  flex-1 flex-row justify-between">
                      <div className="py-2">
                        <p>{money(e.debit, 0)}</p>
                        <p>{e.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
        {reimbursement?.status == "DRAFT" && (
          <div className="p-4">
            <Button
              color="blue"
              appearance="primary"
              block
              onClick={() => {
                setIsLoading(true);
                reimbursementProcess(reimbursement?.id)
                  .then(() => {
                    getDetail();
                  })
                  .catch((err) => errorToast(`${err}`))
                  .finally(() => setIsLoading(false));
              }}
            >
              Proses Reimbursement
            </Button>
          </div>
        )}
      </div>
      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)} size={"xs"}>
        <Modal.Header>
          <Modal.Title>Tambah Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Jumlah </p>
            {mountValue ? (
              <CurrencyInput
                className="form-control"
                groupSeparator="."
                decimalSeparator=","
                value={amount}
                onValueChange={(value, _, values) => {
                  setAmount(values?.float ?? 0);
                }}
              />
            ) : (
              <div className="form-control"> {money(amount)}</div>
            )}
          </div>
          <div className="mb-4">
            <p className="font-[700]">File</p>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf"
              onSuccess={(response, file) => {
                setIsLoading(false);
                setAttachment(response.data.path);
                setAttachmentUrl(response.data.url);
                setIsImg(mime.getType(response.data.url)!.includes("image"));
                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                toaster.push(<Message type="error">Upload failed</Message>);
              }}
            >
              <button
                style={{
                  width: attachmentUrl ? 300 : 100,
                  height: attachmentUrl ? 300 : 100,
                }}
              >
                {attachment ? (
                  isImg ? (
                    <img src={attachmentUrl} alt="" />
                  ) : (
                    <IoDocumentAttachOutline size={64} />
                  )
                ) : (
                  <PaperClipIcon className="w-8" />
                )}
              </button>
            </Uploader>
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              placeholder="ex: bensin ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(false);
              addItemReimbursement(reimbursement?.id!, {
                amount,
                notes: description,
                reimbursement_id: reimbursement?.id!,
                files: JSON.stringify(attachment ? [attachment] : []),
              })
                .then(() => {
                  getDetail();
                  setModalAddOpen(false);
                  setDescription("")
                  setAmount(0)
                  setAttachment("")
                  setAttachmentUrl("")
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default ReimbursementDetail;
