import { FC } from "react"
import { Route, Routes } from "react-router-dom"
import ActivityPage from "../pages/Activity"
import CalendarPage from "../pages/Calendar"
import CashAdvancePage from "../pages/CashAdvance"
import Home from "../pages/Home"
import LeavePage from "../pages/Leave"
import NotFound from "../pages/NotFound"
import Payslip from "../pages/Payslip"
import PayslipDetail from "../pages/PayslipDetail"
import ProfilePage from "../pages/Profile"
import ReimbursementPage from "../pages/Reimbursement"
import ReimbursementDetail from "../pages/ReimbursementDetail"
import ResignationPage from "../pages/Resignation"
import ResignationDetail from "../pages/ResignationDetail"
import TaskDetail from "../pages/TaskDetail"
import CashAdvanceDetail from "../pages/CashAdvanceDetail"
import EmployeeLoanPage from "../pages/EmployeeLoan"
import EmployeeLoanDetail from "../pages/EmployeeLoanDetail"
import Overtime from "../pages/Overtime"
import OvertimeDetail from "../pages/OvertimeDetail"
import OvertimePage from "../pages/Overtime"
import NotificationPage from "../pages/NotificationPage"
import WebPush from "../pages/WebPush"
import OvertimeApprovalPage from "../pages/OvertimePage"
import AnnouncementDetail from "../pages/AnnouncementDetail"
import AnnouncementPage from "../pages/AnnouncementPage"
import ClockInPage from "../pages/ClockInPage"
import ClockOutPage from "../pages/ClockOutPage"
import WorkReportDetail from "../pages/WorkReportDetail"
import WorkReportPage from "../pages/WorkReport"


interface PrivateRoutesProps { }

const PrivateRoutes: FC<PrivateRoutesProps> = ({ }) => {
   
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/leave' element={<LeavePage />} />
            <Route path='/reimbursement' element={<ReimbursementPage />} />
            <Route path='/reimbursement/:reimbursementId' element={<ReimbursementDetail />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/payslip' element={<Payslip />} />
            <Route path='/payslip/:payslipId' element={<PayslipDetail />} />
            <Route path='/task/:taskId' element={<TaskDetail />} />
            <Route path='/activity' element={<ActivityPage />} />
            <Route path='/resignation' element={<ResignationPage />} />
            <Route path='/cash_advance' element={<CashAdvancePage />} />
            <Route path='/employee_loan' element={<EmployeeLoanPage />} />
            <Route path='/employee_loan/:employeeLoanId' element={<EmployeeLoanDetail />} />
            <Route path='/cash_advance/:cashAdvanceId' element={<CashAdvanceDetail />} />
            <Route path='/calendar' element={<CalendarPage />} />
            <Route path='/resignation/:resignationId' element={<ResignationDetail />} />
            <Route path='/notification' element={<NotificationPage />} />
            <Route path='/webpush' element={<WebPush />} />
            <Route path='/overtime' element={<OvertimePage />} />
            <Route path='/overtime/:overtimeId' element={<OvertimeDetail />} />
            <Route path='/approval/overtime/:overtimeId' element={<OvertimeApprovalPage />} />
            <Route path='/announcement/:announcementId' element={<AnnouncementDetail />} />
            <Route path='/announcement' element={<AnnouncementPage />} />
            <Route path='/clockIn' element={<ClockInPage />} />
            <Route path='/clockOut/:attendanceId' element={<ClockOutPage />} />
            <Route path='/approval/work_report/:workReportId' element={<WorkReportDetail needApprovement  needCheck={false}/>} />
            <Route path='/work_report/:workReportId' element={<WorkReportDetail needApprovement={false}  needCheck={false}/>} />
            <Route path='/work_report/:workReportId/check' element={<WorkReportDetail needApprovement={false}  needCheck={true}/>} />
            <Route path='/work_report' element={<WorkReportPage />} />
            {/* <Route path='/cam' element={<WebcamPage />} /> */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default PrivateRoutes