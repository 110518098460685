import {  AnnouncementReq } from "../model/annoucement";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";




export const getAnnouncements = async (pagination: PaginationReq) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }


    if (pagination.order) {
        params["order_by"] = pagination.order
    }
    return await customFetch(`user/announcement?${new URLSearchParams(params)}`)
}


export const deleteAnnouncement = async (id: string) => {
    return await customFetch(`user/announcement/${id}`, {
        method: "DELETE",
    })
}

export const getAnnouncementDetail = async (id: string) => {
    return await customFetch(`user/announcement/${id}`)
}


export const addAnnouncement = async (req: AnnouncementReq) => {
    return await customFetch(`user/announcement`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}