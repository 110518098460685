import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Moment from "react-moment";
import { Announcement } from "../model/annoucement";
import { Profile } from "../model/auth";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { getAnnouncementDetail } from "../repositories/announcement";
import { errorToast } from "../utils/helper_ui";
import { nl2br } from "../utils/helper";

interface AnnouncementDetailProps {}

const AnnouncementDetail: FC<AnnouncementDetailProps> = ({}) => {
  const nav = useNavigate();

  const [profile, setProfile] = useState<Profile | null>(null);
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState<Announcement | null>(null);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    
    getDetail();
  }, [mounted, announcementId]);

  const getDetail = () => {
    if (!mounted) return;
    if (!announcementId) return;
    setIsLoading(true);
    getAnnouncementDetail(announcementId!)
      .then((v) => v.json())
      .then((v) => {
        setAnnouncement(v.data);
      })
      .catch((err) => errorToast(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>{announcement?.name ?? "Pengumuman"}</h3>
        <div></div>
      </div>
      <div className="flex flex-col gap-4 p-4 bg-white rounded-md w-full h-full">
        <div className="flex flex-col gap-2">
          <div>
            <p className="font-bold">Tgl</p>
            <Moment format="DD MMM YYYY">{announcement?.start_date}</Moment> ~ 
            <Moment format="DD MMM YYYY">{announcement?.end_date}</Moment>
          </div>
          <div>
            <p className="font-bold">Keterangan</p>
            <div dangerouslySetInnerHTML={{__html: nl2br(announcement?.description ?? "")}}></div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default AnnouncementDetail;
