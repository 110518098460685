import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import { Button, DatePicker, Modal, SelectPicker, Uploader } from "rsuite";
import { asyncLocalStorage } from "../utils/helper";
import { APPROVER_ID, TOKEN } from "../utils/constant";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import { createActivity, getEmployees } from "../repositories/activity";
import { profile } from "console";
import { Profile } from "../model/auth";
import { FaClock } from "react-icons/fa6";
import { Attendance } from "../model/attendance";
import { Employee } from "../model/employee";
import { Overtime } from "../model/overtime";
import { ActivityReq } from "../model/activity";

interface ModalTaskProps {
  profile?: Profile | null;
  attendance?: Attendance | null;
  overtime?: Overtime | null;
  isOpen: boolean;
  onClose?: (s: boolean) => void;
  onSuccess?: () => void;
}

const ModalTask: FC<ModalTaskProps> = ({
  isOpen,
  onClose,
  profile,
  onSuccess,
  attendance,
  overtime,
}) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState<Date>(moment().toDate());
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [paths, setPaths] = useState<string[]>([]);
  const [assignedToId, setAssignedToId] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeId, setEmployeeId] = useState<string | null>(null);

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    asyncLocalStorage.getItem(APPROVER_ID).then((approverId) => {
      if (!approverId) return;
      getEmployees(
        { page: 1, limit: 5, search: "" },
        { employee_id: approverId }
      )
        .then((v) => v.json())
        .then((v) => {
          setEmployees(v.data);
          setEmployeeId(approverId);
        });
    });
  }, []);

  useEffect(() => {
    setAssignedToId(profile?.employee_id ?? null);
  }, [profile]);
  return (
    <Modal
      size={"xs"}
      open={isOpen}
      onClose={() => {
        onClose?.(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Tambah Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p className="text-sm font-[700]">Nama Task</p>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nama Task"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm font-[700]">Tgl</p>
          <DatePicker
            block
            value={date}
            onChange={(val) => setDate(val!)}
            format="dd MMM yyyy"
          />
        </div>
        <div className="mb-4">
          <p className="text-sm font-[700]">Jam</p>
          <DatePicker
            value={startTime}
            block
            format="HH:mm"
            caretAs={FaClock}
            placement="bottomStart"
            onChange={(val) => {
              setStartTime(val);
            }}
          />
          <p className="text-sm font-[700]">s/d</p>
          <DatePicker
            value={endTime}
            block
            format="HH:mm"
            caretAs={FaClock}
            placement="bottomStart"
            onChange={(val) => {
              setEndTime(val);
            }}
          />
        </div>

        <div className="mb-4">
          <p className="text-sm font-[700]">Keterangan</p>
          <textarea
            className="form-control"
            value={description}
            rows={5}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Keterangan"
          />
        </div>
        <div className="mb-4">
          <p className="font-[700]">Approver</p>
          <SelectPicker
            block
            value={employeeId}
            data={employees}
            labelKey="full_name"
            valueKey="id"
            onClean={() => {
              setEmployeeId(null);
            }}
            onSelect={(val) => {
              setEmployeeId(val);
              asyncLocalStorage.setItem(APPROVER_ID, val);
            }}
            onSearch={(val) => {
              if (val) {
                getEmployees({ page: 1, limit: 5, search: val })
                  .then((v) => v.json())
                  .then((v) => setEmployees(v.data));
              }
            }}
          />
        </div>
        <div className="mb-4">
          <p className="text-sm font-[700]">File</p>
          <Uploader
            draggable
            action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
            onUpload={(file) => {
              setIsLoading(true);
            }}
            headers={{
              authorization: `Bearer ${token}`,
            }}
            accept="image/*, application/pdf"
            onSuccess={(response, file) => {
              setIsLoading(false);
              console.log(response.data.path);
              paths.push(response.data.path);
              setPaths([...paths]);
              // setAttachment(response.data.path);

              // toaster.push(<Message type="success">Uploaded successfully</Message>);
            }}
            onError={() => {
              setIsLoading(false);
              errorToast("Upload Failed");
            }}
          >
            <div
              style={{
                height: 200,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Click or Drag files to this area to upload</span>
            </div>
          </Uploader>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onClose?.(false);
          }}
        >
          Batal
        </Button>
        <Button
          appearance="primary"
          onClick={async () => {
            try {
              if (!name) {
                throw Error("Isi Nama Task");
              }
              if (!startTime) {
                throw Error("Isi Jam terlebih dahulu");
              }
              setIsLoading(true);
              let req : ActivityReq = {
                activity_type: overtime ? "OVERTIME": "TO_DO",
                start_date: date.toISOString(),
                start_time: startTime
                  ? moment(startTime).format("HH:mm:ss")
                  : null,
                end_time: endTime ? moment(endTime).format("HH:mm:ss") : null,
                description,
                assigned_to_id: assignedToId,
                attendance_id: attendance?.id,
                name,
                approver_id: employeeId ?? null,
                status: "IN_PROGRESS",
                files: paths.map((e) => ({
                  path: e,
                  url: "",
                  filename: e.replace(/^.*[\\/]/, ""),
                  caption: e.replace(/^.*[\\/]/, ""),
                })),
              }
              if (overtime) {
                req.overtime_request_id = overtime?.id
              }
              await createActivity(req);
              onClose?.(false);
              onSuccess?.();
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalTask;
