import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import { BsPlus } from "react-icons/bs";
import { TfiTrash } from "react-icons/tfi";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { Badge, Button, DatePicker, Modal, Uploader } from "rsuite";
import MainLayout from "../components/main_layout";
import { Profile } from "../model/auth";
import { CashAdvance } from "../model/cash_advance";
import { LoadingContext } from "../objects/loading_context";
import {
  addCashAdvance,
  deleteCashAdvance,
  getCashAdvances,
} from "../repositories/cash_advance";
import { asyncLocalStorage, confirmDelete, money } from "../utils/helper";
import { errorToast } from "../utils/helper_ui";
import CurrencyInput from "react-currency-input-field";
import { TOKEN } from "../utils/constant";

interface CashAdvancePageProps {}

const CashAdvancePage: FC<CashAdvancePageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);

  const [profile, setProfile] = useState<Profile | null>(null);
  const nav = useNavigate();
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [date, setDate] = useState(moment().toDate());
  const [message, setMessage] = useState("");
  const [cashAdvances, setCashAdvances] = useState<CashAdvance[]>([]);
  const [notes, setNotes] = useState("");
  const [amountRequested, setAmountRequested] = useState(0);
  const [mountValue, setMountValue] = useState(true);
  const [token, setToken] = useState("");

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllCashAdvances();
  }, [mounted]);

  const getAllCashAdvances = () => {
    getCashAdvances({ page: 1, limit: 20 })
      .then((v) => v.json())
      .then((v) => {
        setCashAdvances(v.data);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="flex  flex-col  w-full h-full ">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Cash Advance</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="p-4">
          {cashAdvances.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 "
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.date_requested}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.date_requested}
                </Moment>
              </div>
              <div className="flex  flex-1 flex-row justify-between">
                <div
                  className="py-2 flex-1"
                  onClick={() => nav(`/cash_advance/${e.id}`)}
                >
                  {e.status == "PENDING" && (
                    <Badge
                      color="orange"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "APPROVED" && (
                    <Badge
                      color="green"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "REJECTED" && (
                    <Badge color="red" className="text-xs" content={e.status} />
                  )}
                  <p className="text-sm">{e.employee.full_name}</p>
                  {e.status == "PENDING" && (
                    <p className="text-sm">{money(e.amount_requested)}</p>
                  )}
                  {e.status == "APPROVED" && (
                    <p className="text-sm">{money(e.amount)}</p>
                  )}
                  {e.remarks && (
                    <div className=" text-ellipsis overflow-hidden h-[100px]">
                      <p className="text-sm font-semibold mt-4">Catatan</p>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={e.remarks}
                        className={"text-sm"}
                      />
                    </div>
                  )}
                </div>
                <div className="py-4 flex flex-col items-center w-12 justify-between">
                  {e.status == "PENDING" && (
                    <TfiTrash
                      className="text-red-300"
                      size={18}
                      onClick={() => {
                        confirmDelete(() => {
                          setIsLoading(true);
                          deleteCashAdvance(e.id)
                            .then(() => {
                              getAllCashAdvances();
                            })
                            .catch((err) => errorToast(`${err}`))
                            .finally(() => setIsLoading(false));
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Cash Advance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Tgl</p>
            <DatePicker
              value={date}
              block
              placement="bottomStart"
              format="dd/MM/yyyy"
              onChange={(val) => {
                setDate(val!);
              }}
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">Jumlah yang diajukan</p>
            {mountValue && (
              <CurrencyInput
                className="form-control"
                groupSeparator="."
                decimalSeparator=","
                value={amountRequested}
                onValueChange={(value, _, values) => {
                  setAmountRequested(values?.float ?? 0);
                }}
              />
            )}
          </div>
          {/* <div className="mb-4">
            <p className="font-[700]">File</p>
            <Uploader
              draggable
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onSuccess={(response, file) => {
                setIsLoading(false);

                // setAttachment(response.data.path);

                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                errorToast("Upload Failed");
              }}
            >
              <div
                style={{
                  height: 200,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Click or Drag files to this area to upload</span>
              </div>
            </Uploader>
          </div> */}
          <div className="mb-4">
            <p className="font-[700]">Catatan</p>
            <textarea
              placeholder="ex: Perdin ke bandung ...."
              rows={5}
              value={notes}
              onChange={(el) => setNotes(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              addCashAdvance({
                date_requested: date.toISOString(),
                notes: notes,
                amount_requested: amountRequested,
                cash_advance_number: `CA ${profile?.full_name} - ${moment(
                  date
                ).format("DD/MM/YYYY")}`,
              })
                .then((v) => v.json())
                .then((v) => {
                  setModalAddOpen(false);
                  nav(`/cash_advance/${v.data.id}`);
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default CashAdvancePage;
