import moment from "moment"
import { AttendanceFilter, AttendanceReq, AttendanceWithSite } from "../model/attendance"
import { customFetch } from "../utils/helper"
import { PaginationReq } from "../objects/pagination"


export const addAttendance = async (req: AttendanceReq) => {
    return await customFetch(`user/attendance`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export const attendanceSite = async (req: AttendanceWithSite) => {
    return await customFetch(`user/attendance/site`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export const clockOutAttendance = async (id:string, req: AttendanceReq) => {
    return await customFetch(`user/attendance/clockout/${id}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export const breakAttendance = async (id:string, req: AttendanceReq) => {
    return await customFetch(`user/attendance/break/${id}`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export const todayAttendance = async () => {
    return await customFetch(`user/attendance/today`)
}
export const attendanceDetail = async (id:string) => {
    return await customFetch(`user/attendance/${id}`)
}

export const getAttendances = async  (pagination: PaginationReq, filter?: AttendanceFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }
    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = moment(filter.dateRange[1]).add(1, 'days').toISOString()
        }
        if (filter.jobTitleID) {
            params["job_title_id"] = filter.jobTitleID
        }
      
        if (filter.gender) {
            params["gender"] = filter.gender
        }
        if (filter.orderBy) {
            params["order_by"] = filter.orderBy
        }
        
        if (filter.download) {
            params["download"] = "1"
        }
    }
    return await customFetch(`user/attendance?${new URLSearchParams(params)}`)
} 