import moment from "moment";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";
import { ActivityApprovalReq, ActivityFilter, ActivityReq } from "../model/activity";
import { EmployeeFilter } from "../model/employee";

export const getMyActivities = async (
  pagination: PaginationReq,
  filter?: ActivityFilter
) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  if (filter) {
    if (filter.dateRange) {
      params["start_date"] = filter.dateRange[0].toISOString();
      params["end_date"] = moment(filter.dateRange[1])
        .add(1, "days")
        .toISOString();
    }
    if (filter.status) {
      params["status"] = filter.status;
    }
    if (filter.assignedToId) {
      params["assigned_to_id"] = filter.assignedToId;
    }
    if (filter.activityType) {
      params["activity_type"] = filter.activityType;
    }
    if (filter.order) {
      params["order"] = filter.order;
    }
    if (filter.filter) {
      params["filter"] = filter.filter;
    }
  }
  return await customFetch(`user/activity?${new URLSearchParams(params)}`);
};

export const createActivity = async (req: ActivityReq) => {
  return await customFetch(`user/activity`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const deleteActivity = async (id: string) => {
  return await customFetch(`user/activity/${id}`, {
    method: "DELETE",
  });
};
export const updateActivity = async (id: string, req: ActivityReq) => {
  return await customFetch(`user/activity/${id}`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export const approvalActivity = async (id: string, req: ActivityApprovalReq) => {
  return await customFetch(`user/activity/${id}/Approval`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
};
export const detailActivity = async (id: string) => {
  return await customFetch(`user/activity/${id}`, {});
};
export const getEmployees = async (
  pagination: PaginationReq,
  filter?: EmployeeFilter
) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }

  if (filter) {
    if (filter.employee_id) {
      params["employee_id"] = filter.employee_id;
    }
  }
  return await customFetch(`user/employee?${new URLSearchParams(params)}`, {});
};
