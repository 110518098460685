import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Avatar, Badge, Button, DatePicker, Modal, Uploader } from "rsuite";
import { BsPlus } from "react-icons/bs";
import { LoadingContext } from "../objects/loading_context";
import {
  cancelResignation,
  deleteResignation,
  getResignationDetail,
} from "../repositories/resignation";
import { Resignation } from "../model/resignation";
import { errorToast, successToast } from "../utils/helper_ui";
import { confirmDelete } from "../utils/helper";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import ReactMarkdown from "react-markdown";
import { Profile } from "../model/auth";
import MainLayout from "../components/main_layout";

const ResignationDetail = () => {
  const [mounted, setMounted] = useState(false);

  const [profile, setProfile] = useState<Profile | null>(null);

  const { resignationId } = useParams();
  const [resignation, setResignation] = useState<Resignation>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const nav = useNavigate();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [resignationId, mounted]);
  const getDetail = () => {
    setIsLoading(true);
    getResignationDetail(resignationId!)
      .then((res) => res.json())
      .then((res) => {
        setResignation(res.data);
        // if (res.data.attachment) {
        //   setPreview(URL.createObjectURL(new Blob([res.data.attachment])));
        // }
      })
      .catch((err) => errorToast(`${err}`))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)}>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Detail Pengunduran Diri</h3>
        <div></div>
      </div>
      <div className="flex flex-col gap-4 p-4 bg-white rounded-md w-full h-full">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 justify-between mb-4">
            <div>
            <p className="text-lg font-bold">{resignation?.employee?.full_name}</p>
            <p className="text-sm">{resignation?.employee?.job_title}</p>
            <p className="text-sm">{resignation?.employee?.branch}</p>

            </div>
            <Avatar circle src={resignation?.employee?.picture_url} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-[700]">NIP</p>
            <p className="font-normal text-sm">
              {resignation?.employee?.employee_identity_number}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-[700]">Nama Perusahaan</p>
            <p className="font-normal text-sm">{resignation?.company?.name}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="text-sm font-[700]">Tanggal Pengajuan</p>
            <p className="font-normal text-sm">
              {moment(resignation?.request_date).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-[700]">Tanggal Efektif</p>
            <p className="font-normal text-sm">
              {moment(resignation?.effective_date).format("DD MMMM YYYY")}
            </p>
          </div>
          {resignation?.approved_date && (
            <div className="flex flex-col gap-2">
              <p className="text-sm font-[700]">Tanggal Approval</p>
              <p className="font-normal text-sm">
                {moment(resignation?.approved_date).format("DD MMMM YYYY")}
              </p>
            </div>
          )}
          {resignation?.approved_date && (
            <div className="flex flex-col gap-2">
              <p className="text-sm font-[700]">Disetujui / Ditolak</p>
              <p className="font-normal text-sm">
                {resignation?.approved_by.full_name}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-[700]">Alasan</p>
          <ReactMarkdown
            disallowedElements={["img"]}
            className="font-normal text-sm"
            children={resignation?.message}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-[700]">Alasan Penolakan/ Persetujuan</p>
          <ReactMarkdown
            disallowedElements={["img"]}
            className="font-normal text-sm"
            children={resignation?.reason}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-[700]">Status</p>
          <div>
            {resignation?.status == "PENDING" && (
              <Badge content={resignation?.status} color="orange" />
            )}
            {resignation?.status == "APPROVED" && (
              <Badge content={resignation?.status} color="green" />
            )}
            {resignation?.status == "REJECTED" && (
              <Badge content={resignation?.status} color="red" />
            )}
          </div>
        </div>

        {resignation?.status == "PENDING" && (
          <div className="flex gap-2">
            <Button
              block
              appearance="primary"
              color="red"
              onClick={() => setOpenCancelModal(true)}
            >
              Batalkan
            </Button>
          </div>
        )}
      </div>
      <Modal
        size={"xs"}
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
      >
        <Modal.Header>Konfirmasi Pembatalan</Modal.Header>
        <Modal.Body>
          <p>Anda yakin ingin membatalkan pengajuan resign ini?</p>
          <p className="text-red-500">Aksi ini tidak dapat dikembalikan</p>
          <textarea
            className="form-control"
            placeholder="Berikan Alasan Pembatalan"
            value={reason}
            onChange={(val) => setReason(val.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenCancelModal(false)}>Batal</Button>
          <Button
            appearance="primary"
            onClick={() => {
              setIsLoading(true);
              cancelResignation(resignationId!, reason)
                .then((res) => res.json())
                .then((res) => {
                  setOpenCancelModal(false);
                  getDetail();
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};

export default ResignationDetail;
