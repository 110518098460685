import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { PayRoll } from "../model/payroll";
import { errorToast } from "../utils/helper_ui";
import { getPayslipDetail, getPayslipPDF } from "../repositories/auth";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Company } from "../model/company";
import { Profile } from "../model/auth";
import Moment from "react-moment";
import moment from "moment";
import { getAttendances } from "../repositories/attendance";
import { Attendance } from "../model/attendance";
import { money, stringHourToNumber } from "../utils/helper";
import { getLeaves } from "../repositories/leave";
import { Leave } from "../model/leave";
import { Divider } from "rsuite";
import saveAs from "file-saver";

interface PayslipDetailProps {}

const PayslipDetail: FC<PayslipDetailProps> = ({}) => {
  const { payslipId } = useParams();
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [payslip, setPayslip] = useState<PayRoll | null>(null);
  const [totalDays, setTotalDays] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalOvertime, setTotalOvertime] = useState(0);
  const [totalLateIn, setTotalLateIn] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [company, setCompany] = useState<Company | null>(null);
  const [dateAttendanceRanges, setDateAttendanceRanges] = useState<
    moment.Moment[]
  >([]);
  const [attendances, setAttendances] = useState<Attendance[]>([]);

  const [profile, setProfile] = useState<Profile | null>(null);
  const nav = useNavigate();
  const [leaves, setLeaves] = useState<Leave[]>([]);
  const [absents, setAbsents] = useState<Leave[]>([]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
  }, [mounted, payslipId]);

  const getDetail = async () => {
    try {
      setIsLoading(true);
      const resp = await getPayslipDetail(payslipId!);
      const respJson = await resp.json();
      setPayslip(respJson.data);

      // getAttendances(
      //   { page: 1, limit: 1000 },
      //   {
      //     dateRange: [
      //       moment(respJson.data.start_date).toDate(),
      //       moment(respJson.data.end_date).toDate(),
      //     ],
      //   }
      // )
      //   .then((v) => v.json())
      //   .then((v) => {
      //     setAttendances(v.data);
      //   });

      // getLeaves(
      //   { page: 1, limit: 100 },
      //   {
      //     status: "APPROVED",

      //     dateRange: [
      //       moment(respJson.data.start_date).toDate(),
      //       moment(respJson.data.end_date + " 23:59:59").toDate(),
      //     ],
      //   }
      // )
      //   .then((v) => v.json())
      //   .then((v) => setLeaves(v.data));
      // getLeaves(
      //   { page: 1, limit: 100 },
      //   {
      //     absent: true,
      //     dateRange: [
      //       moment(respJson.data.start_date).toDate(),
      //       moment(respJson.data.end_date + " 23:59:59").toDate(),
      //     ],
      //   }
      // )
      //   .then((v) => v.json())
      //   .then((v) => setAbsents(v.data));
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (payslip) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const resp = await getAttendances(
            { page: 1, limit: 0 },
            {
              employeeID: payslip.employee_id,
              dateRange: [
                moment(moment(payslip!.start_date).format("YYYY-MM-DD")).toDate(),
                moment(moment(payslip!.end_date).format("YYYY-MM-DD"))
                  .add(1, "day")
                  .subtract(1, "seconds")
                  .toDate(),
              ],
            }
          );
          const respJson = await resp.json();
          setAttendances(respJson.data);
        } catch (error) {
          errorToast(`${error}`);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
      getAllLeaves()
    }
  }, [payslip]);

  useEffect(() => {
    if (payslip) {
      const ranges: Date[] = [];
      let totalDays = 0;
      let totalHours = 0;
      let totalOvertime = 0;
      let totalLateIn = 0;

      const itineraryArray = [];
      let currentDate = moment(payslip!.start_date);
      while (currentDate.isSameOrBefore(moment(payslip!.end_date))) {
        ranges.push(currentDate.toDate());
        const att = getAttendancesFromRange(currentDate);
        for (const a of att) {
          if (a.clock_out && a.clock_in)
            totalHours += moment(a.clock_out).diff(
              moment(a.clock_in),
              "minutes"
            );
          if (a.overtime) totalOvertime += stringHourToNumber(a.overtime);
          if (a.late_in) totalLateIn += stringHourToNumber(a.late_in);
        }
        currentDate = currentDate.add(1, "day");
        if (att.length) {
          totalDays++;
        }
      }

      setTotalDays(totalDays);
      setTotalHours(totalHours / 60);
      setTotalOvertime(totalOvertime);
      setTotalLateIn(totalLateIn);

      setDateAttendanceRanges([]);
      setDateAttendanceRanges(ranges.map((e) => moment(e)));
    }
  }, [payslip, attendances]);

  useEffect(() => {
    let totalAbsent = 0
    for (const element of leaves) {
      if (element.leave_category.absent) {
        totalAbsent++
      }
    }
    setTotalAbsent(totalAbsent)
  }, [leaves]);

  const getAllLeaves = async () => {
    try {
      setIsLoading(true);
      getLeaves(
        { page: 1, limit: 100 },
        {
          employeeID: payslip!.employee_id,
          status: "APPROVED",

          dateRange: [
            moment(moment(payslip!.start_date).format("YYYY-MM-DD")).toDate(),
            moment(moment(payslip!.end_date).format("YYYY-MM-DD"))
              .add(1, "day")
              .subtract(1, "seconds")
              .toDate(),
          ],
        }
      )
        .then((v) => v.json())
        .then((v) => setLeaves(v.data));
      // getAllAbsents();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  // const getAllAbsents = async () => {
  //   getLeaves(
  //     { page: 1, limit: 100 },
  //     {
  //       employeeID: payslip!.employee_id,
  //       absent: true,
  //       status: "APPROVED",
  //       dateRange: [
  //         moment(moment(payslip!.start_date).format("YYYY-MM-DD")).toDate(),
  //         moment(moment(payslip!.end_date).format("YYYY-MM-DD"))
  //           .add(1, "day")
  //           .subtract(1, "seconds")
  //           .toDate(),
  //       ],
  //     }
  //   )
  //     .then((v) => v.json())
  //     .then((v) => setAbsents(v.data));
  // };

  // useEffect(() => {
  //   for (const e of dateAttendanceRanges) {
  //     const selAtt = getAttendancesFromRange(e);
  //     const absent = absents.find(
  //       (a) =>
  //         moment(a.start_date).format("DD-MM-YYYY") ==
  //         e.format("DD-MM-YYYY")
  //     );
  //   }
  // }, [dateAttendanceRanges]);

  const getAttendancesFromRange = (date: moment.Moment): Attendance[] => {
    return attendances.filter(
      (e) =>
        moment(e.clock_in).format("DD-MM-YYYY") == date.format("DD-MM-YYYY")
    );
  };

  return (
    <MainLayout
      hideMenu
      onLoadCompany={(val) => setCompany(val)}
      onLoadProfile={(val) => setProfile(val)}
    >
      <div className=" bg-white h-full w-full   overflow-y-auto">
        <div className="h-[320px] bg-orange-500 w-full"></div>
        <div className="-mt-[300px] p-4 flex flex-row gap-4 h-[80px]">
          <ChevronLeftIcon className="text-white w-8" onClick={() => nav(-1)} />
          <div className="flex-1">
            <p className="text-white text-xl font-[700]">{company?.name}</p>
            <p className="text-white text-sm  text-ellipsis">
              {company?.address} {company?.phone} {company?.email}
            </p>
          </div>
        </div>
        <div className="p-8 mt-[50px]  ">
          <div className="bg-white rounded-2xl w-full min-h-[120px] p-4">
            <h3 className="text-center text-3xl font-bold">Payslip</h3>
            <div className="m-auto flex justify-center items-center">
              <Moment format="DD MMM YYYY" className="text-sm text-center">
                {payslip?.start_date}
              </Moment>{" "}
              ~{" "}
              <Moment format="DD MMM YYYY" className="text-sm text-center">
                {payslip?.end_date}
              </Moment>
            </div>
            <div className="mt-8">
              <div className="mb-2">
                <p className="font-[700]">No.PayRoll</p>
                <p className="text-sm">{payslip?.pay_roll_number}</p>
              </div>
              <div className="mb-2">
                <p className="font-[700]">Nama</p>
                <p className="text-sm">{payslip?.employee_name}</p>
              </div>
              <div className="mb-2">
                <p className="font-[700]">NIK/NIP</p>
                <p className="text-sm">
                  {payslip?.employee?.employee_identity_number}
                </p>
              </div>
              <div className="mb-2">
                <p className="font-[700]">Jabatan</p>
                <p className="text-sm">{payslip?.employee?.job_title}</p>
              </div>
              <div className="mb-2">
                <p className="font-[700]">Periode</p>
                <Moment format="DD MMM YYYY" className="text-sm text-center">
                  {payslip?.start_date}
                </Moment>{" "}
                ~{" "}
                <Moment format="DD MMM YYYY" className="text-sm text-center">
                  {payslip?.end_date}
                </Moment>
              </div>
              <div className="grid grid-cols-3 gap-2">
                <div className="mb-2">
                  <p className="font-[700]"> Hari Kerja</p>
                  <p className="text-sm">{totalDays} Hari</p>
                </div>
                <div></div>
                <div className="mb-2">
                  <p className="font-[700]"> Jam Kerja</p>
                  <p className="text-sm">{money(totalHours)} Jam</p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2">
                <div className="mb-2">
                  <p className="font-[700]">Sakit</p>
                  <p className="text-sm">
                    {money(
                      leaves
                        .filter((e) => e.sick && !e.absent)
                        .map((e) => e.diff)
                        .reduce((a, b) => a + b, 0)
                    )}{" "}
                    Hari
                  </p>
                </div>
                <div className="mb-2">
                  <p className="font-[700]">Ijin</p>
                  <p className="text-sm">
                    {money(
                      leaves
                        .filter((e) => !e.sick && !e.absent)
                        .map((e) => e.diff)
                        .reduce((a, b) => a + b, 0)
                    )}{" "}
                    Hari
                  </p>
                </div>
                <div className="mb-2">
                  <p className="font-[700]">Alpa</p>
                  <p className="text-sm">
                    {money(totalAbsent)}{" "}
                    Hari
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <p className="font-bold text-xl mt-4">Uraian</p>
              <p className="font-bold text-base mt-2">Pendapatan</p>
              <ul>
                {(payslip?.items ?? [])
                  .filter((e) => e.item_type != "DEDUCTION")
                  .map((e) => (
                    <li
                      key={e.id}
                      className="flex justify-between flex-row gap-2"
                    >
                      <div>
                        <p className="text-sm">{e.title}</p>
                        <small>{e.notes}</small>
                      </div>
                      <div>{money(e.amount, 0)}</div>
                    </li>
                  ))}
                {(payslip?.tax_allowance ?? 0) > 0 && (
                  <li className="flex justify-between flex-row gap-2">
                    <div>
                      <p className="text-sm">Tunjangan PPH</p>
                    </div>
                    <div>{money(payslip?.tax_allowance, 0)}</div>
                  </li>
                )}
                <hr className="my-2" />
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">Total Pendapatan</p>
                  </div>
                  <div>{money(payslip?.total_income, 0)}</div>
                </li>
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">
                      Total Reimbursement
                    </p>
                  </div>
                  <div>{money(payslip?.total_reimbursement, 0)}</div>
                </li>
                {/* {payslip?.costs
                  .filter((e) => !e.debt_deposit)
                  .map((e) => (
                    <li
                      key={e.id}
                      className="flex justify-between flex-row gap-2"
                    >
                      <div>
                        <p className="font-bold text-base mt-2">
                          {e.description}
                          {` (${money(e.tariff * 100)}%)`}
                        </p>
                      </div>
                      <div>{money(e?.amount, 0)}</div>
                    </li>
                  ))} */}
              </ul>
              <p className="font-bold text-base mt-4">POTONGAN</p>
              <ul>
                {(payslip?.items ?? [])
                  .filter(
                    (e) =>
                      e.item_type == "DEDUCTION" && !e.is_tax_cost && !e.is_tax
                  )
                  .map((e) => (
                    <li
                      key={e.id}
                      className="flex justify-between flex-row gap-2"
                    >
                      <div>
                        <p className="text-sm">{e.title}</p>
                        <small>{e.notes}</small>
                      </div>
                      <div>{money(e.amount, 0)}</div>
                    </li>
                  ))}

                <hr className="my-2" />
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">Total Potongan</p>
                  </div>
                  <div>{money(payslip?.total_deduction, 0)}</div>
                </li>
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">PPH</p>
                  </div>
                  <div>{money(payslip?.total_tax, 0)}</div>
                </li>
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">Biaya Jabatan</p>
                  </div>
                  <div>{money(payslip?.tax_cost, 0)}</div>
                </li>
                <li className="flex justify-between flex-row gap-2">
                  <div>
                    <p className="font-bold text-base mt-2">Take Home Pay</p>
                  </div>
                  <div>
                    {money(
                      (payslip?.take_home_pay ?? 0) +
                        (payslip?.total_reimbursement ?? 0),
                      0
                    )}
                  </div>
                </li>
              </ul>
              <div className="p-4 text-center bg-purple-500 text-white mt-4">
                {payslip?.take_home_pay_reimbursement_counted}
              </div>
              <div
                className="p-4 text-center cursor-pointer"
                onClick={() => {
                  setIsLoading(true);
                  getPayslipPDF(payslipId!)
                    .then((res) => res.blob())
                    .then((res) => {
                      saveAs(
                        res,
                        `${profile?.full_name}-${moment(
                          payslip?.start_date
                        ).format("DDMMYYY")}-${moment(payslip?.end_date).format(
                          "DDMMYYY"
                        )}.pdf`
                      );
                    })
                    .catch((err) => {
                      errorToast(err);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              >
                Download
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default PayslipDetail;
