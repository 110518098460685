import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import { WebcamOutput } from "../objects/webcam";
import { b64toBlob, customFetch } from "../utils/helper";
import { errorToast, setError } from "../utils/helper_ui";
import { CameraIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { Attendance } from "../model/attendance";
import moment from "moment";

const videoConstraints = {
  facingMode: "user",
};

interface WebcamPageProps {
  onCapture: (s: WebcamOutput) => void;
  onClose: () => void;
  attendance?: Attendance | null;
}

const width = 500;
const height = 500;

const WebcamPage: FC<WebcamPageProps> = ({
  onCapture,
  onClose,
  attendance,
}) => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [showCamera, setShowCamera] = useState(true);
  const [loading, setLoading] = useState(false);

  // const webcamRef = useRef<Webcam>(null)

  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }: CameraOptions) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(position);
        },
        (error) => {
          setError("Lokasi tidak dapat diakses");
        }
      );
    } else {
      setError(
        "Geolocation tidak didukung oleh browser Anda, data geolocation diperlukan untuk mengetahui posisi anda ketika melakukan absen"
      );
    }
  }, []);

  const capture = useCallback(() => {
    let ref = webcamRef as RefObject<Webcam>;
    const imageSrc = ref?.current?.getScreenshot();
    // console.log(imageSrc)
    // return
    if (imageSrc) {
      setLoading(true);
      const blob = b64toBlob(
        imageSrc.split("data:image/jpeg;base64,")[1],
        "image/jpg"
      );
      // const blobUrl = URL.createObjectURL(blob);
      const formData = new FormData();

      formData.append("file", blob);
      formData.append("flipped", "1");
      // window.open(blobUrl)
      customFetch(
        `user/upload`,
        {
          method: "POST",
          body: formData,
        },
        true
      )
        .then((v) => v.json())
        .then((v) => {
          onCapture({
            path: v.data.path,
            latitude: location?.coords.latitude,
            longitude: location?.coords.longitude,
            isFaceDetected: facesDetected > 0,
            remarks: "",
          });
        })
        .catch((e) => {
          errorToast(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [webcamRef, location, facesDetected]);

  return (
    <div className="">
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          backgroundColor: "black",
        }}
      >
        <div style={{ width, height, position: "relative" }}>
          {/* {boundingBox.map((box, index) => (
            <div
              key={`${index + 1}`}
              style={{
                border: "4px solid red",
                position: "absolute",
                top: `${box.yCenter * 100}%`,
                left: `${box.xCenter * 100}%`,
                width: `${box.width * 100}%`,
                height: `${box.height * 100}%`,
                zIndex: 1,
                objectFit: "cover",
              }}
            />
          ))} */}
          {showCamera && (
            <Webcam
              ref={webcamRef}
              forceScreenshotSourceSize
              mirrored={true}
              videoConstraints={videoConstraints}
              screenshotFormat="image/jpeg"
              style={{
                position: "absolute",
                textAlign: "center",
                right: 0,
                height: "100vh",
                width: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <div className="absolute top-1 left-1 z-[1000] p-2 rounded-full bg-black bg-opacity-80">
          <XMarkIcon
            className=" w-6 text-white"
            onClick={() => {
              const ref = webcamRef as RefObject<Webcam>;
              ref.current?.stream?.getTracks().forEach((track) => {
                // console.log(track)
              });
              onClose();
            }}
          />
        </div>
      </div>

      {attendance && (
        <div
          className={`absolute  z-50  bg-transparent bottom-0 text-center text-xs text-white p-2`}
        >
          Latest Clockin {moment(attendance.clock_in).format("HH:mm")} @{" "}
          {attendance?.clock_in_notes}
        </div>
      )}

      <div
        className={`absolute  z-50  bg-transparent top-0 rounded-lg p-4 left-5 right-5 flex justify-center transition-opacity ease-in duration-200 ${
          facesDetected == 0 ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="p-4 rounded-lg bg-orange-200">
          Wajah Tidak Terdeteksi
        </div>
      </div>

      {!loading && (
        <div className="absolute  z-50  bg-transparent bottom-20 rounded-lg p-4 left-5 right-5 flex justify-center">
          <div
            className={`p-4 rounded-full ${
              facesDetected > 0 ? "bg-green-700" : "bg-purple-700"
            } `}
            onClick={() => {
              capture();
            }}
          >
            <CameraIcon className="w-16 text-white cursor-pointer" />
          </div>
        </div>
      )}
    </div>
  );
};
export default WebcamPage;
