import type { FC } from "react";
import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Message,
  Modal,
  Pagination as RSPagination,
  SelectPicker,
  toaster,
  Uploader,
} from "rsuite";
import MainLayout from "../components/main_layout";
import { Leave } from "../model/leave";
import { LoadingContext } from "../objects/loading_context";
import { Pagination } from "../objects/pagination";
import {
  addLeave,
  deleteLeave,
  getLeaveCategories,
  getLeaves,
} from "../repositories/leave";

import { TfiTrash } from "react-icons/tfi";
import { asyncLocalStorage, confirmDelete } from "../utils/helper";
import { errorToast } from "../utils/helper_ui";

import { ChevronLeftIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { BsPlus } from "react-icons/bs";
import { FaClock, FaFileCircleQuestion } from "react-icons/fa6";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { LeaveCategory } from "../model/leave_category";
import { TOKEN } from "../utils/constant";
import { IoDocumentAttachOutline } from "react-icons/io5";
import mime from "mime";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { getAllSchedules } from "../repositories/auth";
import { Schedule } from "../model/schedule";

interface LeavePageProps {}

const LeavePage: FC<LeavePageProps> = ({}) => {
  const [leaves, setLeaves] = React.useState<Leave[]>([]);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [categories, setCategories] = useState<LeaveCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSchedule, setSelectedSchedule] = useState<string | null>(null);
  const [requestType, setRequestType] = useState("FULL_DAY");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>([
    moment().add(1, "days").toDate(),
    moment().add(7, "days").toDate(),
  ]);

  const [attachment, setAttachment] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [token, setToken] = useState("");
  const [description, setDescription] = useState("");
  const [isImg, setIsImg] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  const nav = useNavigate()

  useEffect(() => {
    getLeaveCategories({ page: 1, limit: 0 })
      .then((v) => v.json())
      .then((v) => {
        setCategories(v.data);
      });

    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
  }, []);

  useEffect(() => {
    getAllLeaves();
  }, [page, limit, search]);

  const getAllLeaves = () => {
    getLeaves({ page, limit, search })
      .then((v) => v.json())
      .then((v) => {
        setLeaves(v.data);
        setPagination(v.pagination);
      });
  };

  useEffect(() => {
    if (selectedCategory) {
      // console.log(categories.find(e => e.id == selectedCategory))
      setShowSchedule(
        categories.find((e) => e.id == selectedCategory)?.is_shift_schedule
          ? true
          : false
      );
      if (categories.find((e) => e.id == selectedCategory)?.is_shift_schedule) {
        getAllSchedules()
          .then((v) => v.json())
          .then((v) => {
            setSchedules(v.data);
          });
      }
    }
  }, [selectedCategory]);
  return (
    <MainLayout hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Cuti</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          {leaves.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0"
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.start_date}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.start_date}
                </Moment>
              </div>
              <div className="flex  flex-1 flex-row justify-between">
                <div className="py-2">
                  <p
                    className={`${
                      e.absent ? "text-red-400" : "text-blue-400"
                    } text-sm`}
                  >
                    {e.absent ? "Tidak Hadir" : e.leave_category_name}
                  </p>
                  {e.schedule && <small>{e.schedule.name}</small>}

                  <div className="flex flex-col">
                    <div>
                      {e.request_type == "FULL_DAY" && (
                        <>
                          <Moment format="DD MMM YYYY" className="text-xs">
                            {e.start_date}
                          </Moment>{" "}
                          ~{" "}
                          <Moment format="DD MMM YYYY" className="text-xs">
                            {e.end_date}
                          </Moment>
                        </>
                      )}
                      {e.request_type == "HALF_DAY" && (
                        <>
                          <Moment
                            format="DD MMM YYYY, HH:mm"
                            className="text-xs"
                          >{`${e.start_date} ${e.start_time}`}</Moment>{" "}
                          ~{" "}
                          <Moment
                            format="HH:mm"
                            className="text-xs"
                          >{`${e.start_date} ${e.end_time}`}</Moment>
                        </>
                      )}
                    </div>
                    <div>
                      {!e.absent && e.status == "DRAFT" && (
                        <Badge
                          color="cyan"
                          className="text-xs"
                          content={e.status}
                        />
                      )}
                      {!e.absent && e.status == "APPROVED" && (
                        <Badge
                          color="green"
                          className="text-xs"
                          content={e.status}
                        />
                      )}
                      {!e.absent && e.status == "REVIEWED" && (
                        <Badge
                          color="orange"
                          className="text-xs"
                          content={e.status}
                        />
                      )}
                      {!e.absent && e.status == "REJECTED" && (
                        <Badge
                          color="red"
                          className="text-xs"
                          content={e.status}
                        />
                      )}
                    </div>
                    <p>{e.description}</p>
                    {e.remarks && (
                      <>
                        <p className="text-sm font-semibold mt-4">Catatan</p>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={e.remarks}
                          className={"text-sm"}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="py-4 flex flex-col items-center w-12 justify-between">
                  {e.attachment_url && (
                    <Link to={e.attachment_url} target="_blank">
                      {mime.getType(e.attachment_url)?.includes("image") ? (
                        <Avatar circle src={e.attachment_url} />
                      ) : (
                        <IoDocumentAttachOutline
                          size={32}
                          className="text-gray-400"
                        />
                      )}
                    </Link>
                  )}
                  {e.status == "DRAFT" && (
                    <TfiTrash
                      className="text-red-300"
                      size={18}
                      onClick={() => {
                        confirmDelete(() => {
                          setIsLoading(true);
                          deleteLeave(e.id)
                            .then(() => {
                              getAllLeaves();
                            })
                            .catch((err) => errorToast(`${err}`))
                            .finally(() => setIsLoading(false));
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {leaves.length == 0 && (
            <div className="flex justify-center flex-col py-32 h-full px-4">
              <p className="text-center">Belum ada data cuti</p>
              <Button
                onClick={() => setModalAddOpen(true)}
                className="w-full"
                color="blue"
                appearance="primary"
              >
                Ajukan Cuti
              </Button>
            </div>
          )}

          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="bg-white flex justify-center p-4">
              <RSPagination
                total={pagination?.total_records ?? 0}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Cuti</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Jenis </p>
            <SelectPicker
              placeholder="Jenis Cuti / Izin"
              searchable={false}
              data={[
                { value: "FULL_DAY", label: "Full Day" },
                { value: "HALF_DAY", label: "Setengah Hari" },
                { value: "HOURLY", label: "Per Jam" },
              ]}
              value={requestType}
              onSelect={(val) => setRequestType(val)}
              block
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">Kategori</p>
            <SelectPicker
              block
              labelKey="name"
              valueKey="id"
              // searchable={false}
              data={categories}
              value={selectedCategory}
              onChange={(val) => {
                setSelectedCategory(val ?? "");
              }}
            />
          </div>
          {showSchedule && (
            <div className="mb-4">
              <p className="font-[700]">Jadwal</p>
              <SelectPicker
                block
                labelKey="name"
                valueKey="id"
                // searchable={false}
                data={schedules}
                value={selectedSchedule}
                onChange={(val) => {
                  setSelectedSchedule(val ?? "");
                }}
              />
            </div>
          )}
          <div className="mb-4">
            <p className="font-[700]">File</p>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
              onUpload={(file) => {
                setIsLoading(true);
              }}
              headers={{
                authorization: `Bearer ${token}`,
              }}
              accept="image/*, application/pdf"
              onSuccess={(response, file) => {
                setIsLoading(false);
                setAttachment(response.data.path);
                setAttachmentUrl(response.data.url);
                setIsImg(mime.getType(response.data.url)!.includes("image"));
                // toaster.push(<Message type="success">Uploaded successfully</Message>);
              }}
              onError={() => {
                setIsLoading(false);
                toaster.push(<Message type="error">Upload failed</Message>);
              }}
            >
              <button
                style={{
                  width: attachmentUrl ? 300 : 100,
                  height: attachmentUrl ? 300 : 100,
                }}
              >
                {attachment ? (
                  isImg ? (
                    <img src={attachmentUrl} alt="" />
                  ) : (
                    <IoDocumentAttachOutline size={64} />
                  )
                ) : (
                  <PaperClipIcon className="w-8" />
                )}
              </button>
            </Uploader>
          </div>
          <div className="mb-4">
            <p className="font-[700]">Tgl</p>
            {requestType == "FULL_DAY" && (
              <>
                <DatePicker
                  value={dateRange![0]}
                  block
                  placement="bottomStart"
                  format="dd/MM/yyyy"
                  onChange={(val) => {
                    setDateRange([val!, dateRange![1]]);
                  }}
                />
                <p className="mt-4 font-[700]">Sampai</p>
                <DatePicker
                  value={dateRange![1]}
                  block
                  placement="bottomStart"
                  format="dd/MM/yyyy"
                  onChange={(val) => {
                    setDateRange([dateRange![0], val!]);
                  }}
                />
              </>
            )}
            {requestType != "FULL_DAY" && (
              <>
                <DatePicker
                  value={dateRange![0]}
                  block
                  placement="bottomStart"
                  format="dd/MM/yyyy"
                  onChange={(val) => {
                    setDateRange([val!, dateRange![1]]);
                  }}
                />
                <p className="mt-4 font-[700]">Jam</p>
                <DatePicker
                  value={startTime}
                  block
                  format="HH:mm"
                  caretAs={FaClock}
                  placement="bottomStart"
                  onChange={(val) => {
                    setStartTime(val);
                  }}
                />
                <p className="mt-4 font-[700]">Sampai</p>
                <DatePicker
                  value={endTime}
                  block
                  format="HH:mm"
                  caretAs={FaClock}
                  placement="bottomStart"
                  onChange={(val) => {
                    setEndTime(val);
                  }}
                />
              </>
            )}
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              placeholder="ex: Ijin mau menikah ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              addLeave({
                request_type: requestType,
                leave_category_id: selectedCategory ?? "",
                description: description,
                start_date: dateRange![0].toISOString(),
                end_date:
                  requestType == "FULL_DAY"
                    ? dateRange![1]!.toISOString()
                    : null,
                start_time:
                  requestType == "FULL_DAY"
                    ? null
                    : moment(startTime).format("HH:mm:ss"),
                end_time:
                  requestType == "FULL_DAY"
                    ? null
                    : moment(endTime).format("HH:mm:ss"),
                attachment: attachment != "" ? attachment : null,
                schedule_id: selectedSchedule != "" ? selectedSchedule : null,
                status: "DRAFT",
              })
                .then((res) => {
                  toaster.push("Sukses");
                  setDescription("");
                  setAttachment("");
                  setAttachmentUrl("");
                  setIsImg(false);
                  setModalAddOpen(false);
                  getAllLeaves();
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default LeavePage;
