import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  getOvertimeApproval,
  putOvertimeApproval,
} from "../repositories/overtime";
import { Overtime } from "../model/overtime";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import Moment from "react-moment";
import { Badge, Button } from "rsuite";
import { confirmDelete } from "../utils/helper";

interface OvertimeApprovalPageProps {}

const OvertimeApprovalPage: FC<OvertimeApprovalPageProps> = ({}) => {
  const nav = useNavigate();
  const { overtimeId } = useParams();
  const [moment, setMoment] = useState(false);
  const [overtime, setOvertime] = useState<Overtime | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    setMoment(true);
  }, []);

  useEffect(() => {
    if (!moment) return;
    getDetail();
  }, [moment, overtimeId]);

  const getDetail = () => {
    setIsLoading(true);

    getOvertimeApproval(overtimeId!)
      .then((v) => v.json())
      .then((v) => {
        setOvertime(v.data);
      })
      .finally(() => setIsLoading(false))
      .catch((err) => errorToast(err));
  };

  const approval = (status: string) => {
    confirmDelete(
      () => {
        setIsLoading(true);
        putOvertimeApproval(overtimeId!, status, remarks)
          .then((v) => {
            getDetail();
          })
          .finally(() => setIsLoading(false))
          .catch((err) => errorToast(err));
      },
      "",
      "",
      "Kirim"
    );
  };

  return (
    <MainLayout hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Persetujuan Lembur</h3>
          <div className="w-10"></div>
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto">
          <div className="bg-white rounded-xl shadow-md p-4 flex flex-col gap-4">
            <div className="">
              <p className="font-bold">Diajukan</p>
              <p>{overtime?.employee?.full_name}</p>
            </div>
            <div className="">
              <p className="font-bold">NIK / NIP</p>
              <p>{overtime?.employee?.employee_identity_number ?? "-"}</p>
            </div>
            <div className="">
              <p className="font-bold">Jabatan</p>
              <p>{overtime?.employee?.job_title ?? "-"}</p>
            </div>
            <div className="">
              <p className="font-bold">Waktu Lembur</p>
              <p>
                <Moment format="DD MMM YYYY HH:mm">
                  {overtime?.start_time_request}
                </Moment>{" "}
                ~{" "}
                <Moment format="DD MMM YYYY HH:mm">
                  {overtime?.end_time_request}
                </Moment>{" "}
              </p>
            </div>
            <div className="">
              <p className="font-bold">Status</p>
              <p>
                {overtime?.status == "PENDING" && (
                  <Badge
                    color="cyan"
                    className="text-xs"
                    content={overtime?.status}
                  />
                )}
                {overtime?.status == "REVIEWED" && (
                  <Badge
                    color="green"
                    className="text-xs"
                    content={overtime?.status}
                  />
                )}
                {overtime?.status == "APPROVED" && (
                  <Badge
                    color="blue"
                    className="text-xs"
                    content={overtime?.status}
                  />
                )}
                {overtime?.status == "REJECTED" && (
                  <Badge
                    color="red"
                    className="text-xs"
                    content={overtime?.status}
                  />
                )}
              </p>
            </div>
            <div className="">
              <p className="font-bold">Keterangan</p>
              <p>{overtime?.reason}</p>
            </div>
            {overtime?.status == "PENDING" && (
              <div className="">
                <p className="font-bold">Catatan</p>
                <textarea
                  rows={5}
                  className="form-control"
                  placeholder={"Catatan"}
                  value={remarks}
                  onChange={(el) => setRemarks(el.target.value)}
                />
              </div>
            )}
            {overtime?.status != "PENDING" && (
              <div className="">
                <p className="font-bold">Catatan</p>
                <p>{overtime?.remarks}</p>
              </div>
            )}
            {overtime?.status == "PENDING" && (
              <div className=" flex flex-row gap-2">
                <Button
                  style={{ marginTop: 0 }}
                  block
                  className="h-10"
                  appearance="primary"
                  color="red"
                  onClick={() => {
                    approval("REJECTED");
                  }}
                >
                  Tolak
                </Button>
                <Button
                  style={{ marginTop: 0 }}
                  block
                  className="h-10"
                  appearance="primary"
                  color="green"
                  onClick={() => {
                    approval("APPROVED");
                  }}
                >
                  Setujui
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default OvertimeApprovalPage;
