import { useContext, useEffect, useState, type FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Profile } from "../model/auth";
import { EmployeeLoan } from "../model/employee_loan";
import { LoadingContext } from "../objects/loading_context";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { asyncLocalStorage, money } from "../utils/helper";
import { TOKEN } from "../utils/constant";
import { getEmployeeLoanDetail } from "../repositories/employee_loan";
import { errorToast } from "../utils/helper_ui";
import Moment from "react-moment";
import { Badge } from "rsuite";
import moment from "moment";

interface EmployeeLoanDetailProps {}

const EmployeeLoanDetail: FC<EmployeeLoanDetailProps> = ({}) => {
  const nav = useNavigate();
  const { employeeLoanId } = useParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [employeeLoan, setEmployeeLoan] = useState<EmployeeLoan | null>(null);
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [notes, setNotes] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [token, setToken] = useState("");
  const [mountValue, setMountValue] = useState(true);
  const [description, setDescription] = useState("");

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
    setMounted(true);
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    getDetail();
  }, [mounted, employeeLoanId]);

  const getDetail = () => {
    if (!mounted) return;
    if (!employeeLoanId) return;
    getEmployeeLoanDetail(employeeLoanId!)
      .then((v) => v.json())
      .then((v) => {
        setEmployeeLoan(v.data);
      })
      .catch((err) => errorToast(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Detail Pinjaman</h3>
        <div></div>
      </div>
      <div className="flex flex-col gap-4 p-4 bg-white rounded-md w-full h-full">
        <div className="flex flex-col gap-2">
          <div>
            <p className="font-bold">Tgl </p>
            <Moment format="DD MMM YYYY">{employeeLoan?.date}</Moment>
          </div>

          <div>
            <p className="font-bold">Total Pengajuan</p>
            {money(employeeLoan?.total_amount)}
          </div>
          {employeeLoan?.date_approved_or_rejected && (
            <div>
              <p className="font-bold">Tgl Disetujui / Ditolak</p>
              <Moment format="DD MMM YYYY">
                {employeeLoan?.date_approved_or_rejected}
              </Moment>
            </div>
          )}
          {employeeLoan?.status == "APPROVED" && (
            <>
              <div>
                <p className="font-bold">Total Pembayaran</p>
                {money(employeeLoan?.installments_paid)}
              </div>
              <div>
                <p className="font-bold">Sisa Dana</p>
                {money(employeeLoan?.remaining_amount)}
              </div>
            </>
          )}
          <div>
            <p className="font-bold">Approver</p>
            {employeeLoan?.approved?.full_name}
          </div>
          <div>
            <p className="font-bold">Status</p>
            {employeeLoan?.status == "ONGOING" && (
              <Badge
                className="text-center"
                color="yellow"
                content={employeeLoan?.status}
              />
            )}
            {employeeLoan?.status == "APPROVED" && (
              <Badge
                className="text-center"
                color="blue"
                content={employeeLoan?.status}
              />
            )}
            {employeeLoan?.status == "CANCELLED" && (
              <Badge
                className="text-center"
                color="red"
                content={employeeLoan?.status}
              />
            )}
          </div>

          <div>
            {employeeLoan?.file_url && (
              <div>
                <Link to={employeeLoan?.file_url ?? ""}>
                  <PaperClipIcon className="w-6" />
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div>
            <p className="font-bold">Pembayaran</p>
            <ul>
              {employeeLoan?.pay_roll_installments.map((e) => (
                <li
                  key={e.id}
                  onClick={() => {
                    nav(`/payslip/${e.pay_roll.id}`);
                  }}
                  className=" hover:bg-gray-50 p-2 rounded-lg cursor-pointer"
                >
                  <div>
                    <small>
                      <Moment format="DD MMM YYYY">
                        {e.pay_roll.start_date}
                      </Moment>
                      ~
                      <Moment format="DD MMM YYYY">
                        {e.pay_roll.end_date}
                      </Moment>
                    </small>
                  </div>
                  <div className=" hover:font-bold">
                  {e.pay_roll.pay_roll_number} - {money(e.installment_amount)}

                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default EmployeeLoanDetail;
