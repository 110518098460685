import { ReimbursementFilter, ReimbursementItemReq, ReimbursementReq } from "../model/reimbursement";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper"


export const getReimbursements = async (pagination: PaginationReq, filter?: ReimbursementFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = filter.dateRange[1].toISOString()
        }
        if (filter.jobTitleID) {
            params["job_title_id"] = filter.jobTitleID
        }
        if (filter.employeeIDs) {
            params["employee_ids"] = filter.employeeIDs
        }
   
        if (filter.status) {
            params["status"] = filter.status
        }
        
        if (filter.download) {
            params["download"] = "1"
        }
    }
    return await customFetch(`user/reimbursement?${new URLSearchParams(params)}`)
}


export const deleteReimbursement = async (id: string) => {
    return await customFetch(`user/reimbursement/${id}`, {
        method: "DELETE",
    })
}

export const reimbursementDetail = async (id: string) => {
    return await customFetch(`user/reimbursement/${id}`)
}
export const reimbursementProcess = async (id: string) => {
    return await customFetch(`user/reimbursement/${id}/process`,  {
        method: "PUT",
    })
}

export const addItemReimbursement = async (id: string, req: ReimbursementItemReq) => {
    return await customFetch(`user/reimbursement/${id}/addItem`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export const deleteItemReimbursement = async (id: string, itemId: string) => {
    return await customFetch(`user/reimbursement/${id}/deleteItem/${itemId}`, {
        method: "DELETE",
    })
}

export const addReimbursement = async (req: ReimbursementReq) => {
    return await customFetch(`user/reimbursement`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}