import { OvertimeClockInReq, OvertimeClockOutReq, OvertimeFilter, OvertimeReq } from "../model/overtime";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper"


export const getOvertimes = async (pagination: PaginationReq, filter?: OvertimeFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = filter.dateRange[1].toISOString()
        }
       
  
        if (filter.status) {
            params["status"] = filter.status
        }
       
    } 

    if (pagination.order) {
        params["order_by"] = pagination.order
    }
    return await customFetch(`user/overtime?${new URLSearchParams(params)}`)
}


export const deleteOvertime = async (id: string) => {
    return await customFetch(`user/overtime/${id}`, {
        method: "DELETE",
    })
}
export const addOvertime = async (req: OvertimeReq) => {
    return await customFetch(`user/overtime`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}
export const getOvertimeDetail = async (id: string) => {
    return await customFetch(`user/overtime/${id}`, {
    })
}
export const getOvertimeApproval = async (id: string) => {
    return await customFetch(`user/overtime/${id}/Approval`, {
    })
}
export const putOvertimeApproval = async (id: string, status: string, remarks: string) => {
    return await customFetch(`user/overtime/${id}/Approval`, {
        method: "PUT",
        body: JSON.stringify({status, remarks})
    })
}
export const clockInOvertime = async (id: string, req: OvertimeClockInReq) => {
    return await customFetch(`user/overtime/${id}/ClockIn`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}
export const clockOutOvertime = async (id: string, req: OvertimeClockOutReq) => {
    return await customFetch(`user/overtime/${id}/ClockOut`, {
        method: "PUT",
        body: JSON.stringify(req)
    })
}