import moment from "moment";
import { useContext, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { Activity } from "../model/activity";
import { Profile } from "../model/auth";
import { Employee } from "../model/employee";
import { LoadingContext } from "../objects/loading_context";

import { Tabs } from "rsuite";
import MyActivities from "../components/my_activities";
import MyAssignment from "../components/MyAssignment";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

interface ActivityPageProps {}

const ActivityPage: FC<ActivityPageProps> = ({}) => {
  const [profile, setProfile] = useState<Profile | null>(null);

  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const nav = useNavigate()

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Aktifitas</h3>
          <div></div>
          {/* <BsPlus size={24} onClick={() => setModalAddOpen(true)} /> */}
        </div>
        <div className="overflow-x-auto max-w-[400px]">
          <ul className="flex flex-row p-4">
            <li
              className={`px-4 cursor-pointer py-2 rounded-xl ${
                selectedTab == 0 ? "bg-white shadow-md" : ""
              }`}
              onClick={() => {
                setSelectedTab(0);
              }}
            >
              Aktivitas
            </li>
            <li
              className={`px-4 cursor-pointer py-2 rounded-xl ${
                selectedTab == 1 ? "bg-white shadow-md" : ""
              }`}
              onClick={() => {
                setSelectedTab(1);
              }}
            >
              Kunjungan
            </li>
            <li
              className={`px-4 cursor-pointer py-2 rounded-xl ${
                selectedTab == 2 ? "bg-white shadow-md" : ""
              }`}
              onClick={() => {
                setSelectedTab(2);
              }}
            >
              Penugasan
            </li>
            <li
              className={`px-4 cursor-pointer py-2 rounded-xl ${
                selectedTab == 3 ? "bg-white shadow-md" : ""
              }`}
              onClick={() => {
                setSelectedTab(3);
              }}
            >
              Persetujuan
            </li>
          </ul>
        </div>
        <div className="flex-1 overflow-y-auto">
          <div className="px-4">
            {selectedTab == 0 && <MyActivities filter="assigned" />}
            {selectedTab == 1 && <MyActivities filter="visit" />}
            {selectedTab == 2 && <MyAssignment />}
            {selectedTab == 3 && <MyActivities filter="approver" />}
          </div>
        </div>
        {/* <div className="p-4 ">
          <Tabs defaultActiveKey="1" appearance="pills">
            <Tabs.Tab eventKey="1" title="Aktivitas">
              <MyActivities filter="assigned" />
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title="Kunjungan">
              <MyActivities filter="visit" />
            </Tabs.Tab>
            <Tabs.Tab eventKey="3" title="Penugasan">
              <MyAssignment />
            </Tabs.Tab>
            <Tabs.Tab eventKey="4" title="Persetujuan">
              <MyAssignment />
            </Tabs.Tab>
          </Tabs>
        </div> */}
      </div>
    </MainLayout>
  );
};
export default ActivityPage;
