import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import {
  CheckIcon,
  ChevronLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { errorToast, successToast } from "../utils/helper_ui";
import {
  approvalActivity,
  detailActivity,
  updateActivity,
} from "../repositories/activity";
import { Activity } from "../model/activity";
import moment from "moment";
import { Badge, Button, DatePicker, Modal, Uploader } from "rsuite";
import { FaClock } from "react-icons/fa6";
import mime from "mime";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { TASK_STATUS, TOKEN } from "../utils/constant";
import { asyncLocalStorage } from "../utils/helper";
import { Profile } from "../model/auth";
import Moment from "react-moment";
import { todayAttendance } from "../repositories/attendance";
import { Attendance } from "../model/attendance";

interface TaskDetailProps {}

const TaskDetail: FC<TaskDetailProps> = ({}) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const nav = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const { taskId } = useParams();
  const [activity, setActivity] = useState<Activity | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [description, setDescription] = useState("");
  const [attendance, setAttendance] = useState<Attendance | null>(null);
  const [name, setName] = useState("");

  const [token, setToken] = useState("");
  const [paths, setPaths] = useState<string[]>([]);
  const [assignedToId, setAssignedToId] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isToDo, setIsToDo] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [modalApproval, setModalApproval] = useState(false);
  const [approvalRemarks, setApprovalRemarks] = useState("");

  useEffect(() => {
    setMounted(true);
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
    todayAttendance()
      .then((v) => v.json())
      .then((v) => {
        if (v.data.id) {
          setAttendance(v.data);
        }
      });
  }, [mounted, taskId]);

  useEffect(() => {
    if (activity) {
      setIsEditable(
        activity?.status != "FINISHED" && !(activity?.is_need_approval ?? false)
      );
      setName(activity?.name);
      setDate(
        activity?.start_date
          ? moment(activity?.start_date).toDate()
          : moment().toDate()
      );
      setEndDate(
        activity?.end_date ? moment(activity?.end_date).toDate() : null
      );
      setDescription(activity?.description);

      setStartTime(
        activity?.start_time
          ? moment(`${activity?.start_date} ${activity?.start_time}`).toDate()
          : moment().toDate()
      );
      setEndTime(
        activity?.end_time
          ? moment(`${activity?.start_date} ${activity?.end_time}`).toDate()
          : moment().toDate()
      );

      setPaths(activity?.files?.map((e) => e.path));
    }
  }, [activity]);

  const getDetail = async () => {
    try {
      setIsLoading(true);
      const resp = await detailActivity(taskId!);
      const respJson = await resp.json();
      setActivity(respJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      activity?.is_assignment &&
      activity?.assigned_to_id == profile?.employee_id
    ) {
      setIsToDo(true);
    }
  }, [profile, activity]);

  const update = async (status?: string | null) => {
    try {
      setIsLoading(true);
      let attendanceId: string | null = null;
      if (isToDo && status == "FINISHED") {
        attendanceId = attendance?.id ?? null;
      }
      await updateActivity(activity!.id!, {
        ...activity!,
        start_date: date.toISOString(),
        start_time: startTime ? moment(startTime).format("HH:mm:ss") : null,
        end_time: startTime ? moment(endTime).format("HH:mm:ss") : null,
        description,
        assigned_to_id: assignedToId,
        attendance_id: attendanceId,
        name,
        end_date: activity?.end_date
          ? moment(activity?.end_date).toISOString()
          : null,
        status: status ?? activity?.status,
        files: paths.map((e) => ({
          path: e,
          url: "",
          filename: e.replace(/^.*[\\/]/, ""),
          caption: e.replace(/^.*[\\/]/, ""),
        })),
      });
      successToast("Berhasil di update");
      getDetail();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <MainLayout
      hideMenu
      onLoadProfile={(val) => {
        setProfile(val);
      }}
    >
      <div className="flex  flex-col  w-full h-full ">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Task Detail</h3>
          <div></div>
        </div>
        <div className="flex-1 p-4 bg-gray-50 overflow-y-auto ">
          <div className="mb-4">
            {activity?.is_need_approval && (
              <>
                <p className="font-[700]">Karyawan</p>
                <p className="form-control mb-2">{activity?.employee_name}</p>
              </>
            )}
            <p className="font-[700]">Nama Task</p>
            {isToDo ? (
              <p>{name}</p>
            ) : (
              <input
                readOnly={!isEditable}
                type="text"
                className="form-control"
                value={name}
                onChange={(val) => setName(val.target.value)}
              />
            )}
          </div>

          <div className="mb-4">
            <p className="font-[700]">Tgl</p>
            {isToDo ? (
              <Moment format="DD MMM YYYY">{activity?.start_date}</Moment>
            ) : (
              <DatePicker
                readOnly={!isEditable}
                block
                value={date}
                onChange={(val) => setDate(val!)}
                format="dd MMM yyyy"
              />
            )}
          </div>
          {activity?.is_assignment && (
            <div className="mb-4">
              <p className="font-[700]">Sampai</p>
              {isToDo ? (
                <Moment format="DD MMM YYYY">{activity?.end_date}</Moment>
              ) : (
                <DatePicker
                  readOnly={!isEditable}
                  block
                  value={endDate}
                  onChange={(val) => setEndDate(val!)}
                  format="dd MMM yyyy"
                />
              )}
            </div>
          )}
          {!isToDo && (
            <div className="mb-4">
              <p className="text-sm font-[700]">Jam</p>
              <DatePicker
                readOnly={!isEditable}
                value={startTime}
                block
                format="HH:mm"
                caretAs={FaClock}
                placement="bottomStart"
                onChange={(val) => {
                  setStartTime(val);
                }}
              />
              <p className="text-sm font-[700]">s/d</p>
              <DatePicker
                readOnly={!isEditable}
                value={endTime}
                block
                format="HH:mm"
                caretAs={FaClock}
                placement="bottomStart"
                onChange={(val) => {
                  setEndTime(val);
                }}
              />
            </div>
          )}
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            {isToDo ? (
              <p>{activity?.description}</p>
            ) : (
              <textarea
                readOnly={!isEditable || isToDo}
                rows={5}
                className="form-control"
                value={description}
                onChange={(val) => setDescription(val.target.value)}
              />
            )}
          </div>
          <div className="mb-4 flex flex-col max-w-[400px]">
            <p className="font-[700]">File</p>
            <div className="flex my-4 gap-4">
              {(activity?.files ?? []).map((e) => {
                let isImg = mime.getType(e.url)?.includes("image");
                return (
                  <div
                    key={e.url}
                    className=" aspect-square w-20 bg-gray-200 rounded-lg flex justify-center items-center"
                    onClick={() => window.open(e.url)}
                  >
                    {isImg ? (
                      <img
                        src={e.url}
                        className=" object-cover aspect-square w-20 bg-gray-200 rounded-lg"
                      />
                    ) : (
                      <IoDocumentAttachOutline
                        size={32}
                        className="text-gray-400"
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {isEditable && (
              <Uploader
                draggable
                action={`${process.env.REACT_APP_API_URL}/user/file/upload`}
                onUpload={(file) => {
                  setIsLoading(true);
                }}
                headers={{
                  authorization: `Bearer ${token}`,
                }}
                accept="image/*, application/pdf"
                onSuccess={(response, file) => {
                  setIsLoading(false);
                  console.log(response.data.path);
                  paths.push(response.data.path);
                  setPaths([...paths]);
                  // setAttachment(response.data.path);

                  // toaster.push(<Message type="success">Uploaded successfully</Message>);
                }}
                onError={() => {
                  setIsLoading(false);
                  errorToast("Upload Failed");
                }}
              >
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>Click or Drag files to this area to upload</span>
                </div>
              </Uploader>
            )}
          </div>
          <div className="mb-4 flex flex-col ">
            <p className="font-[700]">Status</p>
            <div className="flex">
              {activity?.status == "DRAFT" && (
                <Badge
                  color="cyan"
                  content={
                    TASK_STATUS.find((s) => s.value == activity?.status)?.label
                  }
                />
              )}
              {activity?.status == "IN_PROGRESS" && (
                <Badge
                  color="blue"
                  content={
                    TASK_STATUS.find((s) => s.value == activity?.status)?.label
                  }
                />
              )}
              {activity?.status == "FINISHED" && (
                <Badge
                  color="green"
                  content={
                    TASK_STATUS.find((s) => s.value == activity?.status)?.label
                  }
                />
              )}
            </div>
          </div>
        </div>

        {activity?.is_need_approval &&
          activity?.approval_status == "PENDING" && (
            <div className="flex flex-row p-4 gap-2 justify-center items-center">
              <Button
                className="p-0 m-0"
                block
                color="green"
                appearance="primary"
                onClick={async () => {
                  setApprovalStatus("APPROVED");
                  setModalApproval(true);
                }}
              >
                APPROVE
              </Button>
              <Button
                className="p-0 m-0"
                style={{ marginTop: 0 }}
                block
                color="red"
                appearance="primary"
                onClick={async () => {
                  setApprovalStatus("REJECTED");
                  setModalApproval(true);
                }}
              >
                REJECT
              </Button>
            </div>
          )}
        {activity?.approval_status != "PENDING" && (
          <div className="flex flex-row p-4  bg-white m-4">
            {activity?.approval_status == "APPROVED" && (
              <div className=" flex flex-row justify-between gap-2">
                <div className="w-8 h-8 rounded-full bg-green-500 p-1">
                  <CheckIcon className="text-white" />
                </div>
                <div className="text-sm flex-1">
                  <Moment format="DD MMM YYYY HH:mm">
                    {activity?.approval_date}
                  </Moment>
                  <br />
                  Task Disetujui :{" "}
                  <span className="font-bold">
                    {activity?.approver_name}
                  </span>{" "}
                  <br />
                  Catatan:{" "}
                  <span className="font-bold">
                    {activity?.approval_remarks}
                  </span>{" "}
                  <br />
                </div>
              </div>
            )}
            {activity?.approval_status == "REJECTED" && (
              <div className=" flex flex-row justify-between gap-2">
                <div className="w-8 h-8 rounded-full bg-red-500 p-1">
                  <XMarkIcon className="text-white" />
                </div>
                <div className="text-sm flex-1">
                  <Moment format="DD MMM YYYY HH:mm">
                    {activity?.approval_date}
                  </Moment>
                  <br />
                  Task Ditolak :{" "}
                  <span className="font-bold">
                    {activity?.approver_name}
                  </span>{" "}
                  <br />
                  Catatan:{" "}
                  <span className="font-bold">
                    {activity?.approval_remarks}
                  </span>{" "}
                  <br />
                </div>
              </div>
            )}
          </div>
        )}
        {isEditable && (
          <div className="flex p-4 flex-col">
            {!isToDo && (
              <Button
                block
                appearance="primary"
                onClick={async () => {
                  if (isEditable) {
                    update(null);
                  }
                }}
              >
                Simpan
              </Button>
            )}
            {activity?.status == "DRAFT" && isToDo && (
              <Button
                block
                color="orange"
                appearance="primary"
                onClick={async () => {
                  if (isEditable) {
                    update("IN_PROGRESS");
                  }
                }}
              >
                Kerjakan
              </Button>
            )}

            {((isToDo && activity?.status == "IN_PROGRESS") ||
              (!isToDo && activity?.status != "FINISHED")) && (
              <Button
                block
                color="green"
                appearance="primary"
                onClick={async () => {
                  if (isEditable) {
                    update("FINISHED");
                  }
                }}
              >
                Selesai
              </Button>
            )}
          </div>
        )}
      </div>
      <Modal
        size={"xs"}
        open={modalApproval}
        onClose={() => setModalApproval(false)}
      >
        <Modal.Header>
          <Modal.Title>Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Remarks</p>
            <textarea
              value={approvalRemarks}
              className="form-control"
              onChange={(e) => setApprovalRemarks(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={async () => {
              setIsLoading(true);
              approvalActivity(taskId!, {
                remarks: approvalRemarks,
                status: approvalStatus,
              })
                .then(() => {
                  setModalApproval(false);
                  successToast("Success");
                  setApprovalRemarks("");
                  setApprovalStatus("");
                  getDetail();
                })
                .catch((err) => {
                  errorToast(err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {approvalStatus == "APPROVED" && "SETUJUI"}
            {approvalStatus == "REJECTED" && "TOLAK"}
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default TaskDetail;
