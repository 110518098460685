import { useContext, useEffect, useState, type FC } from "react";
import { Profile } from "../model/auth";
import MainLayout from "../components/main_layout";
import {
  ChevronLeftIcon,
  EyeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { Badge, Button, DatePicker, Modal } from "rsuite";
import moment from "moment";
import { addResignation, getResignations } from "../repositories/resignation";
import { Resignation } from "../model/resignation";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";

interface ResignationPageProps {}

const ResignationPage: FC<ResignationPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [mounted, setMounted] = useState(false);

  const nav = useNavigate();
  const [modalAddOpen, setModalAddOpen] = useState(false);

  const [date, setDate] = useState(moment().toDate());
  const [message, setMessage] = useState("");
  const [resignations, setResignations] = useState<Resignation[]>([]);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllResignations();
  }, [mounted]);

  const getAllResignations = () => {
    getResignations({ page: 1, limit: 20 })
      .then((v) => v.json())
      .then((v) => {
        setResignations(v.data);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)}>
      <div className="flex  flex-col  w-full h-full ">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Resignation</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="p-4">
          {resignations.map((val, idx) => (
            <div
              key={idx}
              className="w-full flex justify-between items-center bg-white rounded-xl  p-4 shadow mb-4 last:mb-0"
            >
              <div>
                <p className="text-xs">
                  {moment(val.request_date).format("DD MMMM YYYY")}
                </p>
                <p className="text-base font-[600]">{val.employee.full_name}</p>
                <p className="text-base font-[600]">{val.company.name}</p>
                <p className="text-sm">{val.message}</p>
                {val.status == "PENDING" && (
                  <Badge content={val.status} color="orange" />
                )}
                {val.status == "APPROVED" && (
                  <Badge content={val.status} color="green" />
                )}
                {val.status == "REJECTED" && (
                  <Badge content={val.status} color="red" />
                )}
              </div>
              <div>
                <EyeIcon
                  className="w-5 text-blue-400 cursor-pointer"
                  onClick={() => nav(`/resignation/${val.id}`)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
        size="xs"
      >
        <Modal.Header>
          <Modal.Title>Tambah Resignation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <p className="font-[700]">Tgl Efektif</p>
            <DatePicker
              value={date}
              onChange={(val) => setDate(val!)}
              block
              format="dd MMM yyyy"
            />
          </div>
          <div className="mb-4">
            <p className="font-[700]">Keterangan</p>
            <textarea
              className="w-full border p-2"
              value={message}
              onChange={(val) => setMessage(val.target.value)}
              rows={4}
              placeholder="Keterangan"
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            color="green"
            onClick={() => {
              setIsLoading(true);
              addResignation({
                employee_id: profile?.employee_id!,
                request_date: moment().toISOString(),
                effective_date: date.toISOString(),
                message: message,
            
              })
                .then(() => {
                  setModalAddOpen(false);
                  getAllResignations();
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => setIsLoading(false));
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default ResignationPage;
