export let PERMISSIONS = "permissions"
export let COMPANIES = "companies"
export let COMPANY_ID = "company-id"
export let APPROVER_ID = "approver-id"
export let COMPANY = "company"
export let TOKEN = "token"
export let PROFILE = "profile"



export let NON_TAXABLE_CODES = [
    { value: "-", label: "Non Pajak" },
    { value: "TK/0", label: "Tidak Kawin Tanpa Tanggungan" },
    { value: "TK/1", label: "Tidak Kawin 1 Orang Tanggungan" },
    { value: "TK/2", label: "Tidak Kawin 2 Orang Tanggungan" },
    { value: "TK/3", label: "Tidak Kawin 3 Orang Tanggungan" },
    { value: "K/0", label: "Kawin Tanpa Tanggungan" },
    { value: "K/1", label: "Kawin 1 Orang Tanggungan" },
    { value: "K/2", label: "Kawin 2 Orang Tanggungan" },
    { value: "K/3", label: "Kawin 3 Orang Tanggungan" },
    { value: "K/1/0", label: "Kawin Penghasilan Istri Digabung Dengan Suami Tanpa Tanggungan" },
    { value: "K/1/1", label: "Kawin Penghasilan Istri Digabung Dengan Suami 1 Orang Tanggungan" },
    { value: "K/1/2", label: "Kawin Penghasilan Istri Digabung Dengan Suami 2 Orang Tanggungan" },
    { value: "K/1/3", label: "Kawin Penghasilan Istri Digabung Dengan Suami 3 Orang Tanggungan" }
]
export let EMPLOYEE_STATUS = [
    { value: "FULL_TIME", label: "Full Time" },
    { value: "PART_TIME", label: "Paruh Waktu" },
    { value: "FREELANCE", label: "Freelance" },
    { value: "FLEXIBLE", label: "Flexible" },
    { value: "SHIFT", label: "Shift" },
    { value: "SEASONAL", label: "Pekerja Musiman" },
]
export let TASK_STATUS = [
    { value: "DRAFT", label: "DRAFT" },
    { value: "IN_PROGRESS", label: "Dikerjakan" },
    { value: "FINISHED", label: "Selesai" },
    { value: "CANCELED", label: "Batal" },

]
export let ATTENDANCE_PROBLEM = [
    { value: "FACE", label: "Deteksi Wajah" },
    { value: "EARLY_IN", label: "Clock In Lebih Awal" },
    { value: "LATE_OUT", label: "Clock Out melebihi toleransi" },
    { value: "LATE_IN", label: "Keterlambatan" },
    { value: "EARLY_OUT", label: "Pulang Cepat" },
    { value: "LOCATION", label: "Lokasi" },
    { value: "SCHEDULE", label: "Jadwal" },
    { value: "CUSTOM", label: "Lainnya" },
    { value: "FAKE_LOCATION_PROBABILITY", label: "Sistem kami mendeteksi Fake Location" },

]