import { WorkReport, WorkReportCheckReq, WorkReportReq } from "../model/work_report";
import { Pagination, PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";

export const createWorkReport = async (req: WorkReportReq) => {
  return await customFetch(`user/workReport`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};

export const getWorkReportDetail = async (id: string) => {
  return await customFetch(`user/workReport/${id}`);
}
export const approvalWorkReport = async (id: string, status: string, remarks: string) => {
  return await customFetch(`user/workReport/${id}/Approval`, {
    method: "PUT",
    body: JSON.stringify({ status, remarks }),
  });
}
export const checkWorkReport = async (id: string, req: WorkReportCheckReq) => {
  return await customFetch(`user/workReport/${id}/Check`, {
    method: "PUT",
    body: JSON.stringify(req),
  });
}

export const getWorkReports = async (
  pagination: PaginationReq,
  attendanceID?: string | null
) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };

  if (attendanceID) {
    params["attendance_id"] = attendanceID;
  }
  return await customFetch(`user/workReport?${new URLSearchParams(params)}`);
};
