import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { LoadingContext } from "../objects/loading_context";
import { Profile } from "../model/auth";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Pagination } from "../objects/pagination";
import { getAnnouncements } from "../repositories/announcement";
import { Announcement } from "../model/annoucement";
import { errorToast } from "../utils/helper_ui";
import { Pagination as RSPagination } from "rsuite";
import Moment from "react-moment";

interface AnnouncementPageProps {}

const AnnouncementPage: FC<AnnouncementPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    getAllAnnouncements();
  }, [mounted, page, limit, search]);

  const getAllAnnouncements = async () => {
    if (!mounted) return;
    try {
      setIsLoading(true);
      let resp = await getAnnouncements({ page, limit, search });
      const respJson = await resp.json();
      setAnnouncements(respJson.data);
      setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const [profile, setProfile] = useState<Profile | null>(null);
  const nav = useNavigate();

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="flex  flex-col  w-full h-full ">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Pengumuman</h3>
          <div className="w-10"></div>
        </div>
        <div className="p-4 flex-1">
          {announcements.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 cursor-pointer "
              onClick={() => nav(`/announcement/${e.id}`)}
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.start_date}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.start_date}
                </Moment>
              </div>

              <div className="flex flex-col ">
                <div className=" font-bold">{e.name}</div>
                <div className="text-gray-500 text-sm">
                  <Moment format="DD MMM YYYY">{e?.start_date}</Moment> ~
                  <Moment format="DD MMM YYYY">{e?.end_date}</Moment>
                </div>
              </div>
            </div>
          ))}
          
        </div>
        {(pagination?.total_pages ?? 0)  > 1 && (
            <div className="bg-white flex justify-center p-4">
              <RSPagination
                total={pagination?.total_pages ?? 0}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
              />
            </div>
          )}
      </div>
    </MainLayout>
  );
};
export default AnnouncementPage;
