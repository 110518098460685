import { LeaveFilter, LeaveReq } from "../model/leave";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper"


export const getLeaves = async (pagination: PaginationReq, filter?: LeaveFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = filter.dateRange[1].toISOString()
        }
       
  
        if (filter.status) {
            params["status"] = filter.status
        }
       
        if (filter.absent) {
            params["absent"] = filter.absent ? "1" : "0"
        }
        if (filter.show_employee) {
            params["show_employee"] = filter.show_employee ? "1" : "0"
        }

     
       
    } 

    if (pagination.order) {
        params["order_by"] = pagination.order
    }
    return await customFetch(`user/leave?${new URLSearchParams(params)}`)
}


export const deleteLeave = async (id: string) => {
    return await customFetch(`user/leave/${id}`, {
        method: "DELETE",
    })
}
export const getLeaveCategories = async (pagination: PaginationReq,) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    return await customFetch(`user/leave_category?${new URLSearchParams(params)}`, {
    })
}

export const addLeave = async (req: LeaveReq) => {
    return await customFetch(`user/leave`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}