import moment from "moment-timezone";

import { useContext, useEffect, useState, type FC } from "react";
import { Badge, Button, Calendar, DatePicker, Modal } from "rsuite";
import MainLayout from "../components/main_layout";
import { CalendarItem, Holiday } from "../model/holiday";
import { Schedule } from "../model/schedule";
import {
  addSchedule,
  deleteSchedule,
  getHolidays,
  googleCalSync,
  monthlySchedules,
} from "../repositories/auth";
// import { google } from 'googleapis';
import {
  ChevronLeftIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ApiCalendar from "react-google-calendar-api";
import { LiaSyncSolid } from "react-icons/lia";
import { LuLink } from "react-icons/lu";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import { GoogleCal } from "../model/google_cal";
import { LoadingContext } from "../objects/loading_context";
import { confirmDelete, randomStr } from "../utils/helper";
import { errorToast } from "../utils/helper_ui";
import { getAttendances } from "../repositories/attendance";
import { Attendance } from "../model/attendance";

const config = {
  clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_API!,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(config);

interface CalendarPageProps {}

const CalendarPage: FC<CalendarPageProps> = ({}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const calendarId = "primary";
  const [calendarItems, setCalendarItems] = useState<CalendarItem[]>([]);
  const [selectedCalendarItems, setSelectedCalendarItems] = useState<
    CalendarItem[]
  >([]);
  const nav = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<CalendarItem | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);

  const [modalAddOpen, setModalAddOpen] = useState(false);
  useEffect(() => {
    getCalendarItems();
  }, [dateRange]);

  const signIn = () => {
    // console.log("Sign in clicked", apiCalendar.sign);

    apiCalendar
      .handleAuthClick()
      .then(() => {
        console.log("User signed in");
        listUpcomingEvents();
      })
      .catch((err) => {
        console.error("Sign-in failed", err);
      });
  };

  const signOut = () => {
    apiCalendar.handleSignoutClick();
  };
  useEffect(() => {
    // listEvent();
  }, []);

  const listUpcomingEvents = async () => {
    try {
      setIsLoading(true);
      let res = await apiCalendar.listEvents({
        calendarId: calendarId,
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      });
      await googleCalSync(res.result.items);
      getCalendarItems();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(true);
    }
  };
  // const handleSuccess = async (response: CredentialResponse) => {
  //   localStorage.setItem('google_token', JSON.stringify(response.credential));
  //   console.log(response.credential)
  //   // const { access_token } = response;

  //   // // Fetch events from Google Calendar
  //   // await fetchEvents(access_token);
  // };

  const getCalendarItems = async () => {
    try {
      setIsLoading(true);
      let monthly = await monthlySchedules(
        `${moment(dateRange[0]).format("MM")}`,
        `${moment(dateRange[0]).format("YYYY")}`
      );

    
      let monthlyJson = await monthly.json();

      let calItemsMonthly = monthlyJson.data.map((e: Schedule) => ({
        id: e.id,
        title: e.name,
        description: e.description,
        source: e.source,
        data: e.data,
        start_date: e.start_date ? moment(e.start_date) : null,
        event_date: moment(e.event_date),
        end_date: e.end_date ? moment(e.end_date) : null,
        start_time: e.start_time,
        end_time: e.end_time,
        type: "schedule",
        isDeletable: e.is_deletable,
      }));

      let resp = await getHolidays();
      let respJson = await resp.json();
      let holidays: Holiday[] = respJson.data.filter((holiday: Holiday) =>
        moment(holiday.date).isBetween(dateRange[0], dateRange[1])
      );
      let calItems: CalendarItem[] = holidays.map((e) => ({
        id: e.id,
        title: e.description,
        description: "",
        event_date: moment(e.date),
        start_time: "",
        end_time: "",
        type: "holiday",
        isDeletable: false,
      }));

      let respAtt = await   getAttendances(
        { page: 1, limit: 0 },
        {
          dateRange: [
            moment(dateRange[0]).toDate(),
            moment(dateRange[1]).toDate(),
          ],
        }
      );
      let respAttJson = await respAtt.json();
      let attendances: Attendance[] = respAttJson.data;
      let calItemsAtt = attendances.map((e) => ({
        id: e.id,
        title: `CLOCK IN ${moment(e.clock_in).format("DD MMM YYYY")}`,
        description: "",
        event_date: moment(e.clock_in),
        start_time: e.clock_in,
        end_time: e.clock_out,
        type: "attendance",
        isDeletable: false,
      }));
      //   console.log([...calItems, ...calItemsMonthly]);
      setCalendarItems([...calItems, ...calItemsMonthly, ...calItemsAtt]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      // console.log(calendarItems)
      setSelectedCalendarItems(getTodoList(selectedDate!));
    }
  }, [calendarItems, selectedDate]);

  const getTodoList = (date: Date) => {
    
    return calendarItems.filter((e) => e.event_date.format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD"));
  };
  const renderCell = (date: Date) => {
    const list = getTodoList(date);

    if (list.length) {
      return <Badge className="calendar-todo-item-badge" />;
    }

    return null;
  };

  const renderDetail = (item: CalendarItem) => {
    if (item?.source == "google" && item?.data) {
      let data: GoogleCal = JSON.parse(item.data!);
      // console.log(data);
      return (
        <div>
          <h3 className="font-bold">Peserta</h3>
          <ul className=" ">
            {data?.attendees?.map((attendee: any) => (
              <li className="text-sm ml-2" key={randomStr(20)}>
                - {attendee?.email}
              </li>
            ))}
          </ul>
          <h3 className="font-bold">Organizer</h3>
          <p className="text-sm">{data.organizer.displayName}</p>

          <h3 className="font-bold">Link Meeting</h3>
          <p className="text-sm">
            {data.hangoutLink && (
              <Link to={data?.hangoutLink} target="_blank">
                {data.hangoutLink}
              </Link>
            )}
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    <MainLayout>
      <div className="flex  flex-col  w-full h-full">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Kalender</h3>
          <div className="flex">
            <LiaSyncSolid size={20} onClick={signIn} />
          </div>
        </div>
        <Calendar
          showWeekNumbers={true}
          compact
          className="bg-white"
          bordered
          renderCell={renderCell}
          onSelect={(val) => {
            // console.log(calendarItems.filter((e) => e.event_date.isSame(moment(val))));

            // console.log(moment(val).format());
            // console.log(moment(val).tz("Asia/Makassar").format());
            
            setSelectedDate(val);
          }}
          onMonthChange={(val) => {
            setDateRange([
              moment(val).startOf("month").toDate(),
              moment(val).endOf("month").toDate(),
            ]);
          }}
        />
        <div className="flex-grow bg-white flex-1 p-4">
          {selectedDate && (
            <div className="flex justify-between items-center">
              <Moment className="text-lg font-bold" format="DD MMM YYYY">
                {selectedDate}
              </Moment>
              <PlusCircleIcon
                className=" text-blue-400 w-4 cursor-pointer"
                onClick={() => {
                  setModalAddOpen(true);
                }}
              />
            </div>
          )}
          <ul>
            {selectedCalendarItems.map((e) => (
              <li
                key={e.id}
                className="mb-4 last:mb-0 border-b last:border-b-0 pb-4 cursor-pointer flex justify-between flex-row items-center"
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedEvent(e);
                    setModalOpen(true);
                  }}
                >
                  <h3 className="font-bold text-normal">{e.title}</h3>
                  {e.type == 'attendance' && <div className="text-xs">
                    {moment(e.start_time).format("HH:mm")}
                    {e.end_time && ` ~ ${moment(e.end_time).format("HH:mm")}`}
                    </div>}
                  
                  {e.start_date && e.end_date ? (
                    <p className="text-xs">
                      {e.start_date.format("DD MMM YYYY")} ~{" "}
                      {e.end_date.format("DD MMM YYYY")}
                    </p>
                  ) : (
                    <p className="text-xs">
                      {e.start_date && e.start_date.format("DD MMM YYYY")}{" "}
                    </p>
                  )}
                  {e.type != 'attendance' && e.start_time && e.end_time && (
                    <p className="text-xs"> 
                      {moment(
                        `${moment().format("YYYY-MM-DD")} ${e.start_time}`
                      ).format("HH:mm")}{" "}
                      ~{" "}
                      {moment(
                        `${moment().format("YYYY-MM-DD")} ${e.end_time}`
                      ).format("HH:mm")}
                    </p>
                  )}
                </div>
                {e.isDeletable && (
                  <TrashIcon
                    className="w-4 text-red-400"
                    onClick={() => {
                      confirmDelete(() => {
                        setIsLoading(true);
                        deleteSchedule(e.id)
                          .then(() => {
                            getCalendarItems();
                          })
                          .finally(() => {
                            setIsLoading(false);
                          })
                          .catch((error) => {
                            errorToast(`${error}`);
                          });
                      });
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <GoogleLogin
        onSuccess={handleSuccess}
       
      /> */}
      {/* <LoginButton /> */}

      {/* <button onClick={(e) => signIn()}>sign-in</button>
      <button onClick={(e) => signOut()}>sign-out</button> */}
      <Modal size={"xs"} open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Detail Jadwal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-4">
            <div>
              <h3 className="font-bold text-lg">{selectedEvent?.title}</h3>
              {selectedEvent?.source == "google" ? (
                <Link
                  to={selectedEvent?.description}
                  className="flex items-center gap-2"
                  target="_blank"
                >
                  <LuLink />
                  Link
                </Link>
              ) : (
                <p className="text-xs">{selectedEvent?.description}</p>
              )}
            </div>
            <div>
              {selectedEvent?.start_date && selectedEvent?.end_date ? (
                <p className="text-xs">
                  {selectedEvent?.start_date.format("DD MMM YYYY")} ~{" "}
                  {selectedEvent?.end_date.format("DD MMM YYYY")}
                </p>
              ) : (
                <p className="text-xs">
                  {selectedEvent?.start_date &&
                    selectedEvent?.start_date.format("DD MMM YYYY")}{" "}
                </p>
              )}
              {selectedEvent?.start_time && selectedEvent?.end_time && (
                <p className="text-xs">
                  {moment(
                    `${moment().format("YYYY-MM-DD")} ${
                      selectedEvent?.start_time
                    }`
                  ).format("HH:mm")}{" "}
                  ~{" "}
                  {moment(
                    `${moment().format("YYYY-MM-DD")} ${
                      selectedEvent?.end_time
                    }`
                  ).format("HH:mm")}
                </p>
              )}
            </div>
            <div>{renderDetail(selectedEvent!)}</div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Jadwal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="font-semibold">Nama / Judul</p>
          <input
            className="form-control mb-4"
            value={title}
            placeholder="Meeting dengan manager...."
            onChange={(val) => setTitle(val.target.value)}
          />
          <p className="font-semibold">Keterangan</p>
          <textarea
            className="form-control  mb-4"
            rows={5}
            value={description}
            placeholder="Keterangan"
            onChange={(val) => setDescription(val.target.value)}
          />

          <p className="font-semibold">s/d Tgl</p>
          <DatePicker
            block
            value={endDate}
            onChange={(val) => setEndDate(val!)}
            format="dd MMM yyyy"
            className=" mb-4"
          />
          <p className="font-semibold">Mulai</p>
          <DatePicker
            block
            value={startTime}
            onChange={(val) => setStartTime(val!)}
            format="HH:mm"
            className=" mb-4"
          />
          <p className="font-semibold">Selesai</p>
          <DatePicker
            block
            value={endTime}
            onChange={(val) => setEndTime(val!)}
            format="HH:mm"
            className=" mb-4"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await addSchedule({
                  name: title,
                  description,
                  start_date: selectedDate!.toISOString(),
                  end_date: endDate ? endDate!.toISOString() : null,
                  start_time: startTime
                    ? moment(startTime).format("HH:mm:ss")
                    : "",
                  end_time: endTime ? moment(endTime).format("HH:mm:ss") : "",
                  schedule_type: "DATERANGE",
                });
                getCalendarItems();
                setModalAddOpen(false);
                setSelectedCalendarItems(getTodoList(selectedDate!));
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Tambah
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default CalendarPage;
