import { LoginReq } from "../model/auth";
import { GoogleCal } from "../model/google_cal";
import { ScheduleReq } from "../model/schedule";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";

export const login = async (req: LoginReq) => {
  return await customFetch("user/login", {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const resetPassword = async (req: LoginReq) => {
  return await customFetch("user/reset-password", {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const getProfile = async () => {
  return await customFetch("user/my");
};
export const getAllSchedules = async () => {
  return await customFetch("user/my/allSchedules");
};
export const getNotifications = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search ?? "",
  };
  return await customFetch(`user/my/notification?${new URLSearchParams(params)}`);
};
export const getNotificationCount = async () => {
  return await customFetch(`user/my/notification/count`);
};
export const readNotif = async (id: string) => {
  return await customFetch(`user/my/notification/${id}/read`, {
    method: "PUT"
  });
};
export const clearNotif = async () => {
  return await customFetch(`user/my/notification/clear`, {
    method: "PUT"
  });
};

export const todaySchedules = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search ?? "",
  };
  return await customFetch(`user/my/todaySchedules?${new URLSearchParams(params)}`);
};
export const todayOvertime = async () => {
  return await customFetch(`user/my/todayOvertime`);
};
export const monthlySchedules = async (month: string, year: string) => {
  const params: Record<string, string> = {
    month,year
  };
  return await customFetch(`user/my/monthlySchedules?${new URLSearchParams(params)}`);
};
export const googleCalSync = async (data: GoogleCal[]) => {

  return await customFetch(`user/my/syncGoogleCal`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
export const addSchedule = async (data: ScheduleReq) => {

  return await customFetch(`user/my/addSchedule`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
export const deleteSchedule = async (id: string) => {
  return await customFetch(`user/my/deleteSchedule/${id}`, {
    method: "DELETE",
  });
};
export const changePassword = async (password: string) => {
  return await customFetch("user/my/changePassword", {
    method: "PUT",
    body: JSON.stringify({ password }),
  });
};
export const updateAvatar = async (avatar: string) => {
  return await customFetch("user/my/avatar", {
    method: "PUT",
    body: JSON.stringify({ avatar }),
  });
};
export const subscribeChannel = async (subscribe_token: string) => {
  return await customFetch("user/my/subscribe", {
    method: "POST",
    body: JSON.stringify({ subscribe_token }),
  });
};

export const subscribeChannelIos = async (subscribe_token: string) => {
  return await customFetch("user/my/subscribe/ios", {
    method: "POST",
    body: JSON.stringify({ subscribe_token }),
  });
};
export const getHolidays = async () => {
  return await customFetch("user/my/holiday?page=1&limit=0", {
  });
};
export const getPayslips = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
    search: pagination.search ?? "",
  };
  return await customFetch(
    `user/my/payslip?${new URLSearchParams(params)}`,
    {}
  );
};
export const getPayslipDetail = async (id: string) => {
  return await customFetch(`user/my/payslip/${id}`, {});
};
export const getPayslipPDF = async (id: string) => {
  return await customFetch(`user/my/payslip/${id}/pdf`, {});
};

export const verification = async (token: string) => {
  return await customFetch("user/verification/" + token, {
    method: "POST",
  });
};
