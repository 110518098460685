import { useContext, useEffect, useState, type FC } from "react";
import { Profile } from "../model/auth";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Modal } from "rsuite";
import { asyncLocalStorage, confirmDelete, money } from "../utils/helper";
import { TOKEN } from "../utils/constant";
import {
  addEmployeeLoan,
  deleteEmployeeLoan,
  getEmployeeLoans,
} from "../repositories/employee_loan";
import { EmployeeLoan } from "../model/employee_loan";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TfiTrash } from "react-icons/tfi";
import { LoadingContext } from "../objects/loading_context";
import { errorToast } from "../utils/helper_ui";
import CurrencyInput from "react-currency-input-field";

interface EmployeeLoanPageProps {}

const EmployeeLoanPage: FC<EmployeeLoanPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const nav = useNavigate();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [token, setToken] = useState("");
  const [employeeLoans, setEmployeeLoans] = useState<EmployeeLoan[]>([]);
  const [mountValue, setMountValue] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [description, setDescription] = useState("");

  useEffect(() => {
    asyncLocalStorage.getItem(TOKEN).then((v) => setToken(v));
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!mounted) return;
    getAllEmployeeLoans();
  }, [mounted]);

  const getAllEmployeeLoans = () => {
    getEmployeeLoans({ page: 1, limit: 20 })
      .then((v) => v.json())
      .then((v) => {
        setEmployeeLoans(v.data);
      });
  };

  return (
    <MainLayout onLoadProfile={(val) => setProfile(val)} hideMenu>
      <div className="flex  flex-col  w-full h-full ">
        <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
          <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
          <h3>Pinjaman</h3>
          <BsPlus size={24} onClick={() => setModalAddOpen(true)} />
        </div>
        <div className="p-4">
          {employeeLoans.map((e) => (
            <div
              key={e.id}
              className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0 "
            >
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.date}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.date}
                </Moment>
              </div>
              <div className="flex  flex-1 flex-row justify-between">
                <div
                  className="py-2 flex-1"
                  onClick={() => nav(`/employee_loan/${e.id}`)}
                >
                  {e.status == "ONGOING" && (
                    <Badge
                      color="orange"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "APPROVED" && (
                    <Badge
                      color="green"
                      className="text-xs"
                      content={e.status}
                    />
                  )}
                  {e.status == "CANCELLED" && (
                    <Badge color="red" className="text-xs" content={e.status} />
                  )}
                  <p className="text-sm">{e.employee.full_name}</p>
                  {e.status == "ONGOING" && (
                    <p className="text-sm">{money(e.total_amount)}</p>
                  )}
                  {e.status == "APPROVED" && (
                    <>
                      <p className="text-sm">{money(e.total_amount)}</p>
                      <small>Sisa</small>
                      <p className="text-sm">{money(e.remaining_amount)}</p>
                    </>
                  )}
                  {e.remarks && (
                    <div className=" text-ellipsis overflow-hidden min-h-[60px]">
                      <p className="text-sm font-semibold mt-4">Catatan</p>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={e.remarks}
                        className={"text-sm"}
                      />
                    </div>
                  )}
                </div>
                <div className="py-4 flex flex-col items-center w-12 justify-between">
                  {e.status == "ONGOING" && (
                    <TfiTrash
                      className="text-red-300"
                      size={18}
                      onClick={() => {
                        confirmDelete(() => {
                          setIsLoading(true);
                          deleteEmployeeLoan(e.id)
                            .then(() => {
                              getAllEmployeeLoans();
                            })
                            .catch((err) => errorToast(`${err}`))
                            .finally(() => setIsLoading(false));
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        size={"xs"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Pinjaman</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="font-[700]">Jumlah yang diajukan</p>
          {mountValue && (
            <CurrencyInput
              className="form-control"
              groupSeparator="."
              decimalSeparator=","
              value={totalAmount}
              onValueChange={(value, _, values) => {
                setTotalAmount(values?.float ?? 0);
              }}
            />
          )}
          <div className="mb-4">
            <p className="font-[700]">Catatan</p>
            <textarea
              placeholder="ex: Perdin ke bandung ...."
              rows={5}
              value={description}
              onChange={(el) => setDescription(el.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="blue"
            appearance="primary"
            block
            onClick={() => {
              setIsLoading(true);
              addEmployeeLoan({
                total_amount: totalAmount,
                description,
              })
                .then((v) => v.json())
                .then((v) => {
                  setModalAddOpen(false);
                  nav(`/employee_loan/${v.data.id}`);
                })
                .catch((err) => errorToast(`${err}`))
                .finally(() => setIsLoading(false));
            }}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default EmployeeLoanPage;
