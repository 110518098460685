import React, { Suspense, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { LoadingContext } from "./objects/loading_context";
import { CustomProvider } from "rsuite";
import idID from "./objects/id_ID";
import { AppRoute } from "./routes/AppRoute";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  asyncLocalStorage,
  IsIOS,
  urlBase64ToUint8Array,
} from "./utils/helper";
import { subscribeChannel } from "./repositories/auth";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { TOKEN } from "./utils/constant";

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      console.log(e.message);
      if (
        e.message ===
          "ResizeObserver loop completed with undelivered notifications." ||
        e.message.includes("Element type is invalid")
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    const subscribeUser = async () => {
      try {
        if ("serviceWorker" in navigator) {
          let swRegistration = await navigator.serviceWorker.register("/sw.js");
          const registration = await navigator.serviceWorker.ready;
          if (registration) {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(),
            });
            // console.log("User is subscribed:", subscription);
            asyncLocalStorage.getItem(TOKEN).then((v) => {
              if (v) {
                subscribeChannel(JSON.stringify(subscription));
              }
            });
            // if (IsIOS()) {
            //   let pushManager = swRegistration.pushManager;
            //   let permissionState = await pushManager.permissionState({
            //     userVisibleOnly: true,
            //   });
            //   console.log(permissionState)

            //   let subscriptionOptions = {
            //     userVisibleOnly: true,
            //     applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
            // };
            //     let subscription = await pushManager.subscribe(subscriptionOptions);
            //     console.log(subscription);
            // }
          }
        }
      } catch (error) {
        // console.error("Failed to subscribe user:", error);
      }
    };

    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        subscribeUser();
      } else {
        console.log("Notification permission denied");
      }
    };

    requestPermission();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LoadingContext.Provider
        value={{ isLoading: loading, setIsLoading: setLoading }}
      >
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_API_CLIENT_API!}
        >
          <CustomProvider locale={idID}>
            <AppRoute />
          </CustomProvider>
        </GoogleOAuthProvider>
      </LoadingContext.Provider>
    </Suspense>
  );
}

export default App;
