import { EmployeeLoanFilter, EmployeeLoanReq } from "../model/employee_loan";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";




export const getEmployeeLoans = async (pagination: PaginationReq, filter?: EmployeeLoanFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = filter.dateRange[1].toISOString()
        }
       
  
        if (filter.status) {
            params["status"] = filter.status
        }
       
    } 

    if (pagination.order) {
        params["order_by"] = pagination.order
    }
    return await customFetch(`user/employeeLoan?${new URLSearchParams(params)}`)
}


export const deleteEmployeeLoan = async (id: string) => {
    return await customFetch(`user/employeeLoan/${id}`, {
        method: "DELETE",
    })
}
export const cancelEmployeeLoan = async (id: string, reason: string) => {
    return await customFetch(`user/employeeLoan/${id}/cancel`, {
        method: "PUT",
        body: JSON.stringify({reason}),
    })
}
export const getEmployeeLoanDetail = async (id: string) => {
    return await customFetch(`user/employeeLoan/${id}`)
}


export const addEmployeeLoan = async (req: EmployeeLoanReq) => {
    return await customFetch(`user/employeeLoan`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}