import { CashAdvanceFilter, CashAdvanceReq, CashAdvancUsageReq } from "../model/cash_advance";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/helper";




export const getCashAdvances = async (pagination: PaginationReq, filter?: CashAdvanceFilter) => {
    const params: Record<string, string> = {
        page: pagination.page.toString(),
        limit: pagination.limit.toString(),

    };
    if (pagination.search && pagination.search != "") {
        params["search"] = pagination.search
    }

    if (filter) {
       
        if (filter.dateRange) {
            params["start_date"] = filter.dateRange[0].toISOString()
            params["end_date"] = filter.dateRange[1].toISOString()
        }
       
  
        if (filter.status) {
            params["status"] = filter.status
        }
       
    } 

    if (pagination.order) {
        params["order_by"] = pagination.order
    }
    return await customFetch(`user/cashAdvance?${new URLSearchParams(params)}`)
}


export const deleteCashAdvance = async (id: string) => {
    return await customFetch(`user/cashAdvance/${id}`, {
        method: "DELETE",
    })
}
export const cancelCashAdvance = async (id: string, reason: string) => {
    return await customFetch(`user/cashAdvance/${id}/cancel`, {
        method: "PUT",
        body: JSON.stringify({reason}),
    })
}
export const getCashAdvanceDetail = async (id: string) => {
    return await customFetch(`user/cashAdvance/${id}`)
}
export const addUsageCashAdvance = async (id: string, data: CashAdvancUsageReq) => {
    return await customFetch(`user/cashAdvance/${id}/AddUsage`, {
        method: "PUT",
        body: JSON.stringify(data),
    })
}
export const refundCashAdvance = async (id: string, refund_amount: number, description: string, file?: string | null) => {
    return await customFetch(`user/cashAdvance/${id}/Refund`, {
        method: "PUT",
        body: JSON.stringify({refund_amount, description,file}),
    })
}


export const addCashAdvance = async (req: CashAdvanceReq) => {
    return await customFetch(`user/cashAdvance`, {
        method: "POST",
        body: JSON.stringify(req)
    })
}