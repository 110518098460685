import { useContext, useEffect, useState, type FC } from "react";
import MainLayout from "../components/main_layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../objects/loading_context";
import { Profile } from "../model/auth";
import { WorkReport } from "../model/work_report";
import { Pagination } from "../objects/pagination";
import { getWorkReports } from "../repositories/work_report";
import { Badge, Pagination as RSPagination } from "rsuite";
import Moment from "react-moment";

interface WorkReportPageProps {}

const WorkReportPage: FC<WorkReportPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [mounted, setMounted] = useState(false);
  const [workReports, setWorkReports] = useState<WorkReport[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState<Pagination | null>(null);

  const nav = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllWorkReports();
  }, [mounted, page, search, limit]);

  const getAllWorkReports = () => {
    getWorkReports({ page, limit, search })
      .then((v) => v.json())
      .then((v) => {
        setWorkReports(v.data);
        setPagination(v.pagination);
      });
  };

  return (
    <MainLayout onLoadProfile={setProfile}>
      <div className="text-center bg-white p-4 w-full border-b flex justify-between items-center ">
        <ChevronLeftIcon className="w-6" onClick={() => nav(-1)} />
        <h3>Laporan Kerja Harian</h3>
        <div></div>
      </div>
      <div className="flex-1 p-4 bg-gray-50 overflow-y-auto w-full ">
        {workReports.map((e) => (
          <div
            className="p-4 bg-white rounded-lg shadow-sm w-full mb-4 cursor-pointer"
            key={e.id}
            onClick={() => {
              if (e.approved_by_id == profile?.employee_id) {
                nav(`/approval/work_report/${e.id}`);
              } else {
                nav(`/work_report/${e.id}`);
              }
            }}
          >
            <div className="flex flex-row gap-4">
              <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
                <Moment format="DD">{e.submitted_at}</Moment>
                <Moment
                  format="MMM YYYY"
                  className="text-[8pt] text-center text-red-400"
                >
                  {e.submitted_at}
                </Moment>
              </div>
              <div className="flex-1">
                <p className="font-bold text-lg">
                  {e.work_type.name} {e.units_completed} {e.work_type.unit_name}
                </p>
                {e.approved_by_id == profile?.employee_id ? (
                  <p className="text-sm">Diajukan : {e.employee?.full_name}</p>
                ) : (
                  <p className="text-sm">
                    Diajukan Kepada : {e.approved_by?.full_name}
                  </p>
                )}
                <p className="text-sm">
                  Status{" "}
                  {e?.status == "SUBMITTED" && (
                    <Badge content={e?.status} color="orange" />
                  )}
                  {e?.status == "APPROVED" && (
                    <Badge content={e?.status} color="green" />
                  )}
                  {e?.status == "REJECTED" && (
                    <Badge content={e?.status} color="red" />
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
        {(pagination?.total_pages ?? 0) > 1 && (
          <div className="bg-white flex justify-center p-4">
            <RSPagination
              total={pagination?.total_records ?? 0}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};
export default WorkReportPage;
