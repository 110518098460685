import { useContext, useEffect, useState, type FC } from "react";
import WebcamPage from "../components/web_cam";
import { errorToast } from "../utils/helper_ui";
import { LoadingContext } from "../objects/loading_context";
import moment from "moment";
import {
  addAttendance,
  clockOutAttendance,
  todayAttendance,
} from "../repositories/attendance";
import { useNavigate, useParams } from "react-router-dom";
import { Attendance } from "../model/attendance";
import MainLayout from "../components/main_layout";

interface ClockInPageProps {}

const ClockInPage: FC<ClockInPageProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [attendance, setAttendance] = useState<Attendance | null>(null);
  const { attendanceId } = useParams();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    todayAttendance()
      .then((v) => v.json())
      .then((v) => {
        if (v.data.id) {
          setAttendance(v.data);
        }
      });
  }, [mounted]);

  return (
    <MainLayout hideMenu>
      <WebcamPage
        onCapture={async (val) => {
          if (!val.latitude || !val.longitude) {
            errorToast(
              "WARNING, Lokasi anda tidak terdeteksi, hal ini bisa membuat proses clock in gagal"
            );
          }
          try {
            setIsLoading(true);
            //   if (attendance != null) {
            //     await clockOutAttendance(attendance.id!, {
            //       clock_out: moment().toISOString(),
            //       clock_out_lat: val.latitude,
            //       clock_out_lng: val.longitude,
            //       clock_out_picture: val.path,
            //       clock_in: attendance.clock_in,
            //       is_face_detected: val.isFaceDetected,
            //       remarks: val.remarks,
            //     });
            //   } else {
            await addAttendance({
              clock_in: moment().toISOString(),
              clock_in_lat: val.latitude,
              clock_in_lng: val.longitude,
              clock_in_picture: val.path,
              is_face_detected: val.isFaceDetected,
              remarks: val.remarks,
            });
            //   }
            window.location.href = "/";
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
        onClose={() => {
          window.location.href = "/";
        }}
      />
    </MainLayout>
  );
};
export default ClockInPage;
