import { useContext, useEffect, useState, type FC } from "react";
import { LoadingContext } from "../objects/loading_context";
import { Activity } from "../model/activity";
import Moment from "react-moment";
import { Avatar, Badge, Pagination as RSPagination } from "rsuite";
import { TASK_STATUS } from "../utils/constant";
import { deleteActivity, getMyActivities } from "../repositories/activity";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { Pagination } from "../objects/pagination";
import mime from "mime";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { confirmDelete, nl2br } from "../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { EyeIcon } from "@heroicons/react/24/outline";
import { TfiTrash } from "react-icons/tfi";
import { errorToast } from "../utils/helper_ui";
import MapComponent from "./map";
import moment from "moment";

interface MyActivitiesProps {
  filter: string;
}

const MyActivities: FC<MyActivitiesProps> = ({ filter }) => {
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    getAllActivities(filter);
  }, [mounted, page, limit, search, activityType, dateRange]);

  const getAllActivities = async (filter: string) => {
    if (!mounted) return;
    try {
      setIsLoading(true);
      let resp = await getMyActivities(
        { page, limit, search },
        { dateRange, activityType, filter: filter }
      );
      const respJson = await resp.json();
      setActivities(respJson.data);
      setPagination(respJson.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-1 bg-gray-50 overflow-y-auto ">
      {activities.map((e) => (
        <div
          key={e.id}
          className="flex flex-row  gap-4 bg-white p-4 rounded-lg shadow-lg shadow-gray-200 mb-4 last:mb-0"
        >
          <div className="w-16 h-16 bg-white shadow-lg shadow-gray-100 rounded-lg flex-col flex justify-center items-center p-2">
            
            <Moment format="DD">{e.start_date}</Moment>
            <Moment
              format="MMM YYYY"
              className="text-[8pt] text-center text-red-400"
            >
              {e.start_date}
            </Moment>
          </div>
          <div className="flex  flex-1 flex-row justify-between">
            <div className="py-2 cursor-pointer">
              <div className="flex flex-col">
                <div>
                  {e.activity_type == "TO_DO" && <></>}
                  {e.activity_type == "VISIT" && <></>}
                </div>
                <div>
                  {filter == "approver" && <div>
                    {e.employee_name}
                    </div>}
                  {e.status == "DRAFT" && (
                    <Badge
                      color="cyan"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "IN_PROGRESS" && (
                    <Badge
                      color="blue"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "FINISHED" && (
                    <Badge
                      color="green"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                  {e.status == "CANCELED" && (
                    <Badge
                      color="red"
                      content={
                        TASK_STATUS.find((s) => s.value == e.status)?.label
                      }
                    />
                  )}
                </div>
                <div></div>
                <p className="font-[700] text-base">{e.name}</p>
                
                {e.activity_type == "BREAK_START" && <Moment format="HH:mm">{`${moment().format("YYYY-MM-DD")} ${e.start_time}`}</Moment> }
                {e.activity_type == "BREAK_END" && <Moment format="HH:mm">{`${moment().format("YYYY-MM-DD")} ${e.start_time}`}</Moment> }
                <p
                  className="text-xs  hover:font-bold"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/place/${e.lat},${e.lng}`
                    );
                  }}
                >
                  {e.location}
                </p>
                {e.activity_type == "VISIT" && (
                  <div className="mb-4">
                    <MapComponent
                      latitude={e.lat}
                      longitude={e.lng}
                      locationName={e.name}
                      styles={{ borderRadius: 10 }}
                      height={120}
                      width={null}
                    />
                  </div>
                )}
                <p
                  className="text-xs"
                  dangerouslySetInnerHTML={{ __html: nl2br(e.description) }}
                ></p>
                {e.remarks && (
                  <>
                    <p className="text-sm font-semibold mt-4">Catatan</p>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={e.remarks}
                      className={"text-sm"}
                    />
                  </>
                )}
                {e.is_assignment && (
                  <p className=" text-sm">
                    Ditugaskan Oleh:{" "}
                    <span className="font-[700]">{e.employee_name}</span>{" "}
                  </p>
                )}
                <div className="flex flex-row mt-4">
                  {e.files.map((att) => (
                    <Link key={att.url} to={att.url} target="_blank">
                      {mime.getType(att.url)?.includes("image") ? (
                        <Avatar size="sm" circle src={att.url} />
                      ) : (
                        <IoDocumentAttachOutline
                          size={32}
                          className="text-gray-400"
                        />
                      )}
                    </Link>
                  ))}
                </div>
              </div>
              
              {e.approver_id && <div>
                {e.approval_status == "APPROVED" && <Badge content={e.approval_status} color="green" />}
                {e.approval_status == "REJECTED" && <Badge content={e.approval_status} color="red" />}
                </div>}
            </div>

            <div className="py-4 flex flex-col items-center w-12 ">
              {(e.activity_type == "TO_DO" || e.activity_type == "OVERTIME") && (
                <EyeIcon
                  className="text-blue-400 w-5 mb-2 cursor-pointer"
                  onClick={() => {
                    if (e.activity_type == "TO_DO" || e.activity_type == "OVERTIME" ) {
                      nav(`/task/${e.id}`);
                    }
                  }}
                />
              )}
              {e.status != "FINISHED" && (
                <TfiTrash
                  className="text-red-300"
                  size={18}
                  onClick={() => {
                    confirmDelete(() => {
                      setIsLoading(true);
                      deleteActivity(e.id)
                        .then(() => {
                          getAllActivities(filter);
                        })
                        .catch((err) => errorToast(`${err}`))
                        .finally(() => setIsLoading(false));
                    });
                  }}
                />
              )}
            </div>
            
          </div>
        </div>
      ))}
      {/* {activities.length == 0 && (
            <div className="flex justify-center flex-col py-32 h-full px-4">
              <p className="text-center">Belum ada data Aktifitas</p>
              <Button
                onClick={() => setModalAddOpen(true)}
                className="w-full"
                color="blue"
                appearance="primary"
              >
                Tambah Aktifitas
              </Button>
            </div>
          )} */}

      {(pagination?.total_pages ?? 0) > 1 && (
        <div className="bg-white flex justify-center p-4">
          <RSPagination
            total={pagination?.total_records ?? 0}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
          />
        </div>
      )}
    </div>
  );
};
export default MyActivities;
